export const anonStudentIdPrefix = 'anon-'

export const possibleQuizTopics = [
    'Geometri',
    'Trigonometri',
    'Økonomi',
    'Talforståelse',
    '10 ́er-venner',
    'Brøker',
    'Procent',
    'Decimaltal',
    'Algebra',
    'Ligninger',
    'Funktioner',
    'Koordinatsystemet',
    'Grafer og tabeller',
    'Måling',
    'Statistik',
    'Sandsynlighed',
    'Kombinatorik',
    'Blandede opgaver',
    'Tæl & Tal',
    'Omregning af enheder',
    'Addition',
    'Subtraktion',
    'Multiplikation',
    'Division'
]
export const possibleVideoTopics = [
    'Geometri',
    'Trigonometri',
    'Økonomi',
    'Talforståelse',
    '10 ́ervenner',
    'Brøker',
    'Procent',
    'Decimalttal',
    'Algebra',
    'Ligninger',
    'Funktioner',
    'Koordinatsystemet',
    'Grafer og tabeller',
    'Måling',
    'Statistik',
    'Sandsynlighed',
    'Kombinatorik',
    'Modellering',
    'Hjælpemidler',
    'Kommunikation',
    'Problembehandling',
    'Ræsonnement',
    'Repræsentation og Symbolbehandling',
    'Tid & Hastighed',
    'Omregning af enheder',
    'Addition',
    'Subtraktion',
    'Multiplikation',
    'Division'
]

export const possibleQuizCategories = [
    'Escape room',
    'Matematikmission',
    'Emne-Quiz',
    'Læringsquiz',
    'Åben quiz',
    'Lukket quiz',
]
export const possibleVideoCategories = [
    'Videopgave',
    'Spil',
    'Hjernevrider',
    'Praktisk opgave',
    'Mundtlig oplæg',
    'Intro-video',
    'Bevægelse',
    'Undersøgende matematik',
    'Hygge'
]

export const possiblePDFCategories = [
    'Træningsopgaver',
    'Tekstopgaver',
    'Ræsonnementsopgaver',
    'Spil',
    'Mundtlige oplæg',
    'Undersøgende opgaver',
    'Hygge',
    'Praktiske opgaver',
    'Farveopgaver',
    'Årstidsopgaver',
    'Tegn, byg og design',
    'Regnestrategier',
    'Plakater m.m.',
    'Åbne opgaver',
    'Escape Rooms-opgaver',
    'Bevægelsesopgaver'
]

export const gradeLevels = [
    'indskoling', 'mellemtrin', 'udskoling',
    '0. klasse', '1. klasse', '2. klasse',
    '3. klasse', '4. klasse', '5. klasse',
    '6. klasse', '7. klasse', '8. klasse',
    '9. klasse', '10. klasse']

export const indskolingGradeLevels = [
    'indskoling', '0. klasse', '1. klasse', '2. klasse', '3. klasse'
]
export const mellemtrinGradeLevels = [
    'mellemtrin', '4. klasse', '5. klasse', '6. klasse'
]
export const udskolingGradeLevels = [
    'udskoling', '7. klasse', '8. klasse', '9. klasse', '10. klasse'
]
