import {
    createStyles, Grow, makeStyles, Portal, Snackbar, SnackbarContent 
} from '@material-ui/core'
import * as React from 'react'

interface Props {
    text: string,
    setOpen: (open: boolean) => void
    open: boolean,
    type: 'alert' | 'success'
}

const useStyles = makeStyles((theme) => createStyles({
    centeredAlert: {
        [theme.breakpoints.down('sm')]: {
            left: '50%',
            transform: 'translate(-50%) !important',
        },
        [theme.breakpoints.up('md')]: {
            margin: '0 auto'
        },
        zIndex: 1301
    }
}),)

const Alert: React.FC<Props> = ({
    text,
    setOpen,
    open,
    type
}) => { 
    const classes = useStyles()
    const handleClose = (event: any, reason:any) => {
        if (reason === 'clickaway') return
        setOpen(false)
    }

    return (
        <Portal>
            <Grow
                in={open}
                {...(open ? { timeout: 400 } : { timeout: 10000 })}>
                <Snackbar
                    open={open}
                    className={classes.centeredAlert}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    onClose={handleClose}
                    autoHideDuration={3500}>
                    <SnackbarContent 
                        style={{ backgroundColor: type === 'alert' ? '#f44336' : '#4BB543' }} 
                        message={text} />
                </Snackbar>
            </Grow>
        </Portal>
    )
}
export default Alert
