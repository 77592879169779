import {
    Collapse, createStyles, Grid, Link, makeStyles, Typography
} from '@material-ui/core'
import * as React from 'react'
import ReactPlayer from 'react-player'

import { HomeworkType, VideoType } from '../../types/types'
import { showMessage } from '../../utils/notifications'
import { useLibraryStyles } from '../../utils/styles'
import { useTeacher } from '../../utils/TeacherManager'
import LibraryTextProperties from '../atoms/LibraryTextProperties'

interface Props {
    item: VideoType,
}

const useStyles = makeStyles(() => createStyles({
    generalTags: {
        marginBottom: 10,
        marginTop: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly'
    },
}))

const VideoListItem: React.FC<Props> = ({ item }) => {
    const libraryClasses = useLibraryStyles()
    const classes = useStyles()
    const { fetchHomeworks } = useTeacher()
    const { error }:
        { result: HomeworkType[], error: Error, loading: boolean } = fetchHomeworks

    const [open, setOpen] = React.useState<boolean>(false)

    if (error) {
        showMessage(error.message)
    }

    return (
        <div className={libraryClasses.container}>
            <Grid
                className={open ? libraryClasses.secondGrid : libraryClasses.firstGrid}
                onClick={() => setOpen(!open)}
                container>
                <Grid item xs={12} sm={4} className={classes.generalTags}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <img
                            style={{
                                maxWidth: '100%', paddingRight: 10
                            }}
                            src={item.thumbnailUri}
                            alt="Video" />

                    </div>
                </Grid>
                <Grid item xs={12} sm={4} className={classes.generalTags}>
                    <LibraryTextProperties headerText="Titel" smallerText={item.title} />
                    <LibraryTextProperties headerText="Kategori" smallerText={item.categories.join(', ')} />
                </Grid>
                <Grid item xs={12} sm={4} className={classes.generalTags}>
                    <LibraryTextProperties headerText="Beskrivelse" smallerText={item.description} />
                    <LibraryTextProperties
                        headerText="Klassetrin"
                        smallerText={item.grades?.join(', ') || 'Ingen'} />
                </Grid>
            </Grid>
            <Grid container>
                <Collapse className={libraryClasses.collapse} in={open} timeout="auto" unmountOnExit>
                    <div className={libraryClasses.videoContainer}>
                        <ReactPlayer
                            url={`https://player.vimeo.com/video/${item.vimeoVideoId}`}
                            width="100%"
                            controls />
                    </div>
                    <Grid container>
                        <Grid item xs={12} sm={4} className={libraryClasses.container}>
                            <Typography variant="h6">Opgave-fil</Typography>
                            <Typography>
                                <Link
                                    target="_blank"
                                    href={item?.mainFile?.uri}>
                                    {item.mainFile?.name}
                                </Link>
                            </Typography>
                            {item.resultsFile?.uri && (
                                <div>
                                    <Typography variant="h6">Facit-fil</Typography>
                                    <Typography>
                                        <Link
                                            target="_blank"
                                            href={item?.resultsFile?.uri}>
                                            {item.resultsFile?.name}
                                        </Link>
                                    </Typography>
                                </div>
                            )}

                            {item.supplementUri?.map((l) => (
                                <Link
                                    key={l}
                                    target="_blank"
                                    href={l}
                                    rel="noreferrer"> Link til Mattips opgaver om emnet
                                </Link>
                            ))}
                        </Grid>

                    </Grid>
                </Collapse>
            </Grid>
        </div>
    )
}
export default VideoListItem
