import CardGiftcardIcon from '@material-ui/icons/CardGiftcard'
import EmailIcon from '@material-ui/icons/Email'
import HomeIcon from '@material-ui/icons/Home'
import PersonIcon from '@material-ui/icons/Person'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer'
import SportsEsportsIcon from '@material-ui/icons/SportsEsports'
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary'
import * as React from 'react'

type Route = {
    path: string;
    name: string;
    icon?: JSX.Element;
    secure: boolean;
}

const Home: Route = {
    path: '/',
    name: 'Hjem',
    icon: <HomeIcon />,
    secure: true
}

const QuizLibrary: Route = {
    path: '/quizbibliotek',
    name: 'Geeksters quizzer',
    icon: <QuestionAnswerIcon />,
    secure: true
}

const PDFLibrary: Route = {
    path: '/pdfbibliotek',
    name: 'Geeksters kopi-ark',
    icon: <PictureAsPdfIcon />,
    secure: true
}

const PDFShareItem: Route = {
    path: '/del-pdf',
    name: 'Del kopi-ark',
    secure: true
}

const Quiz: Route = {
    path: '/quiz',
    name: 'Quiz Item',
    secure: true
}

const VideoLibrary: Route = {
    path: '/videobibliotek',
    name: 'Geeksters videoer',
    icon: <VideoLibraryIcon />,
    secure: true
}

const Games: Route = {
    path: '/spil',
    name: 'Geeksters spil',
    icon: <SportsEsportsIcon />,
    secure: true
}

const Profile: Route = {
    path: '/profil',
    name: 'Min profil',
    icon: <PersonIcon />,
    secure: true
}

const AboutGeekster: Route = {
    path: '/about',
    name: 'Om geekster',
    secure: true
}

const Login: Route = {
    path: '/login',
    name: 'Login',
    secure: false
}

const SignUp: Route = {
    path: '/signUp',
    name: 'SignUp',
    secure: false
}

const ConfirmSignUp: Route = {
    path: '/confirm',
    name: 'ConfirmSignUp',
    secure: false
}

const ConfirmForgotPassword: Route = {
    path: '/confirm_forgot',
    name: 'ConfirmForgotPassword',
    secure: false
}

const CompleteSignUpWithUniLogin: Route = {
    path: '/signUp/complete',
    name: 'CompleteSignUpWithUniLogin',
    secure: false
}

const CompleteLogInWithUniLogin: Route = {
    path: '/login/complete',
    name: 'CompleteLogInWithUniLogin',
    secure: false
}

const Parents: Route = {
    path: '/parents',
    name: 'Parents',
    secure: false
}
const Coupon: Route = {
    path: '/skoler',
    name: 'Skoler',
    secure: false
}

const TaC: Route = {
    path: '/handelsbetingelser',
    name: 'TermsAndConditions',
    secure: false
}
const Contact: Route = {
    path: '/kontakt-os',
    name: 'Contact Us',
    secure: false
}

const PrivacyPolicy: Route = {
    path: '/privacy-policy',
    name: 'Privacy Policy',
    secure: false
}

const HelpGeekster: Route = {
    path: '/help',
    name: 'Hjælp',
    secure: true,
    icon: <EmailIcon />
}
export const GeeksterOffers: Route = {
    path: '/gaver',
    name: 'Geeksters gaver',
    secure: true,
    icon: <CardGiftcardIcon />
}

export const LibraryDrawerRoutes = [
    QuizLibrary,
    VideoLibrary,
    Games
]

export const TeacherDrawerRoutes = [Home]

export const ProfileRoutes = [Profile, HelpGeekster]

export default {
    PDFLibrary,
    PDFShareItem,
    Coupon,
    Parents,
    PrivacyPolicy,
    Contact,
    TaC,
    Home,
    Quiz,
    QuizLibrary,
    VideoLibrary,
    Games,
    Profile,
    AboutGeekster,
    HelpGeekster,
    Login,
    SignUp,
    ConfirmSignUp,
    ConfirmForgotPassword,
    CompleteSignUpWithUniLogin,
    CompleteLogInWithUniLogin
}
