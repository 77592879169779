import {
    Accordion, AccordionDetails, AccordionSummary, makeStyles, Typography
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import * as React from 'react'

import { BodyWithTopBar } from '../../components/wrappers/BodyWithTopBar'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    content: {
        justifyContent: 'center'
    }
}))

export const GamesLibraryPage: React.FC = () => {
    const classes = useStyles()
    const [expanded, setExpanded] = React.useState<string | false>(false)

    const handleChange = (panel: any) => (_event: any, isExpanded: any) => {
        setExpanded(isExpanded ? panel : false)
    }

    return (
        <BodyWithTopBar>
            <div className={classes.root}>
                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        classes={{ content: classes.content }}>
                        <Typography variant="h4">Math or Splat!</Typography>
                        {/* <Typography className={classes.secondaryHeading}>I am an accordion</Typography> */}
                    </AccordionSummary>
                    <AccordionDetails>
                        <iframe
                            title="Math or Splat!"
                            src="https://i.simmer.io/@GeeksterDK/math-or-splat"
                            style={{
                                width: 960, height: 540, marginRight: 'auto', marginLeft: 'auto'
                            }} />
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2bh-content"
                        id="panel2bh-header"
                        classes={{ content: classes.content }}>
                        <Typography variant="h4">Verdens Sværeste Brøk-Spil!</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <iframe
                            title="Verdens Sværeste Brøk-spil"
                            src="https://i.simmer.io/@GeeksterDK/~7e3df0f2-4750-b62e-9393-2a8a0dad1b26"
                            style={{
                                width: 960,
                                height: 600,
                                marginRight: 'auto',
                                marginLeft: 'auto' 
                            }}/>
                    </AccordionDetails>
                </Accordion>
                <Typography
                    style={{ fontSize: 26 }}
                    align="center">Flere matematikspil er på vej!
                </Typography>

            </div>
        </BodyWithTopBar>
    )
}
