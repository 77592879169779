/* eslint-disable max-len */
import {
    Button, Checkbox, Link, ThemeProvider
} from '@material-ui/core'
import * as React from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import validator from 'validator'

import { AuthButton } from '../../components/molecules/AuthButton'
import { Loading } from '../../components/molecules/Loading'
import { Logo } from '../../components/molecules/Logo'
import { NoAuthenticatedNav } from '../../components/molecules/NoAuthenticatedNav'
import { TextInputTemplate } from '../../components/molecules/TextInputTemplate'
import { SignUpForm } from '../../components/organisms/SignUpForm'
import { Body } from '../../components/wrappers/Body'
import { buttonStyles } from '../../styles/ButtonsStyles'
import { PricePlan } from '../../types/types'
import { showMessage } from '../../utils/notifications'
import { validateCoupon } from '../../utils/Services/reepay-service'
import { signUpTeacher } from '../../utils/Services/teacher-service'
import { buttonTheme, useTableStyles } from '../../utils/styles'
import { wrap } from '../../utils/wrap-functions'

interface Props {
    showSubscriptionOptions: boolean
}

export const SignUp: React.FC<Props> = ({ showSubscriptionOptions }) => {
    const buttonClasses = buttonStyles()
    const tableClasses = useTableStyles()

    const [email, setEmail] = React.useState<string>('')
    const [password, setPassword] = React.useState<string>('')
    const [repeatPassword, setRepeatPassword] = React.useState<string>('')
    // const [school, setSchool] = React.useState<string>('')
    const [firstName, setFirstName] = React.useState<string>('')
    const [lastName, setLastName] = React.useState<string>('')
    const [coupon, setCoupon] = React.useState<string>('')
    const [acceptedTaC, setAcceptedTaC] = React.useState(false)
    const [goToLogin, setGoToLogin] = React.useState(false)
    const [confirmStage, setConfirmStage] = React.useState(false)
    const [signingUp, setSigningUp] = React.useState(false)
    // const [redirectToUniLogin, setRedirectToUniLogin] = React.useState(false)
    const [userId, setUserId] = React.useState<string>('')
    const [selectedPricePlan, setSelectedPricePlan] = React.useState<PricePlan>()
    const [signUpView, setSignUpView] = React.useState<number>(0)

    // const history = useHistory()

    const location = useLocation()

    React.useEffect(() => {
        if (!showSubscriptionOptions) setSelectedPricePlan('1year')
    }, [showSubscriptionOptions])
    const returnErrorIfFormNotValid = async () => {
        let errorMessage = ''
        if (!email) errorMessage = 'Tilføj venligst en gyldig e-mail'

        if (!selectedPricePlan) errorMessage = 'Vælg venligst en prisplan'

        if (coupon?.length) {
            if (!(await validateCoupon(coupon)).isValid) {
                errorMessage = 'Kuponen er ikke gyldig'
            }
        }

        return errorMessage
    }

    const signUp = async () => {
        const formErrorMessage = await returnErrorIfFormNotValid()
        if (formErrorMessage.length > 0) {
            showMessage(formErrorMessage, 'error')
            return
        }
        if (!validator.isStrongPassword(password, {
            minLength: 8, minLowercase: 0, minSymbols: 0, minUppercase: 0, minNumbers: 0
        })) {
            showMessage('Adgangskoden skal være mindst 8 tegn lang', 'error')
            return
        }

        if (repeatPassword !== password) {
            showMessage('Adgangskoderne passer ikke sammen', 'error')
            return
        }
        await wrap(async () => {
            const teacher = await signUpTeacher({
                email,
                password,
                school: '',
                firstName,
                lastName,
                isParent: (signUpView === 0).toString(),
                subscriptionPricePlan: selectedPricePlan as PricePlan,
                coupon: coupon?.length ? coupon : undefined
            })

            setUserId(teacher.id)
            setConfirmStage(true)

        }, {
            showSuccess: true,
            stateFunc: setSigningUp,
            operation: 'Signing Up'
        })

    }

    if (signingUp) {
        return <Loading />
    }

    if (confirmStage) {
        return (
            <Redirect to={{
                pathname: '/confirm',
                state: {
                    email,
                    id: userId,
                    lastName,
                    pricePlan: selectedPricePlan,
                    coupon: coupon?.length ? coupon : undefined
                }
            }} />
        )
    }

    if (goToLogin) {
        return (
            <Redirect to="/login" />
        )
    }

    return (
        <div className={tableClasses.authBackground}>
            <NoAuthenticatedNav onClick={() => setGoToLogin(true)} title="Log ind" />
            <div className={tableClasses.authLogoContainer}>
                <Logo />
            </div>
            <Body>
                <div className={tableClasses.divAuthButtons}>
                    {/* This should be uncommented in order to allow the parents profile creation */}
                    <AuthButton
                        textColor={signUpView === 0 ? '#FFFFFF' : '#000'}
                        backgroundColor={signUpView === 0 ? '#3C8CAC' : '#FFFFFF'}
                        onClick={() => setSignUpView(0)}
                        title="Opret forældreadgang til Geekster"
                        visible={location.pathname !== '/skoler'} />
                    {/* <AuthButton
                        showIcon
                        source={<FacebookIcon/>}
                        textColor={signUpView === 1 ? '#FFFFFF' : '#000'}
                        backgroundColor={signUpView === 1 ? '#3C8CAC' : '#FFFFFF'}
                        onClick={() => setSignUpView(1)}
                        title="Opret forældreadgang med Facebook" />
                    <AuthButton
                        showIcon
                        source={(
                            <svg
                                style={{ marginRight: 0 }}
                                className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc"
                                focusable="false"
                                aria-hidden="true"
                                viewBox="0 0 24 24"
                                data-testid="GoogleIcon">
                                //eslint-disable-next-line max-len 
                                <path d="M12.545,10.239v3.821h5.445c-0.712,2.315-2.647,3.972-5.445,3.972c-3.332,0-6.033-2.701-6.033-6.032s2.701-6.032,6.033-6.032c1.498,0,2.866,0.549,3.921,1.453l2.814-2.814C17.503,2.988,15.139,2,12.545,2C7.021,2,2.543,6.477,2.543,12s4.478,10,10.002,10c8.396,0,10.249-7.85,9.426-11.748L12.545,10.239z" />
                            </svg>
                        )}
                        textColor={signUpView === 2 ? '#FFFFFF' : '#000'}
                        backgroundColor={signUpView === 2 ? '#3C8CAC' : '#FFFFFF'}
                        onClick={() => setSignUpView(2)}
                        title="Opret forældreadgang med Google" /> */}
                    {/* <AuthButton
                        showLogo
                        // source={unilogin}
                        alt="Unilogin logo"
                        textColor={signUpView === 2 ? '#FFFFFF' : '#000'}
                        backgroundColor={signUpView === 2 ? '#3C8CAC' : '#FFFFFF'}
                        onClick={() => setSignUpView(2)}
                        title="Opret lærerprofil med" /> */}
                </div>

                {signUpView !== null && (
                    <SignUpForm
                        showSubscriptionOptions={showSubscriptionOptions}
                        // selectedPricePlan={selectedPricePlan}
                        setSelectedPricePlan={setSelectedPricePlan}>
                        <TextInputTemplate value={email} onChange={setEmail} label="Email" />
                        <TextInputTemplate
                            value={password}
                            label="Password"
                            onChange={setPassword}
                            type="password" />

                        <TextInputTemplate
                            value={repeatPassword}
                            onChange={setRepeatPassword}
                            label="Gentag Password"
                            type="password" />

                        <div className={tableClasses.divInputRow}>
                            <TextInputTemplate
                                value={firstName}
                                onChange={setFirstName}
                                label="Fornavn" />

                            <TextInputTemplate
                                value={lastName}
                                onChange={setLastName}
                                label="Efternavn" />
                        </div>
                        <TextInputTemplate
                            value={coupon}
                            onChange={setCoupon}
                            label="Kupon" />
                        <div className={tableClasses.checkBox}>
                            <Checkbox
                                checked={acceptedTaC}
                                onChange={(e) => setAcceptedTaC(e.target.checked)}
                                inputProps={{ 'aria-label': 'primary checkbox' }} />
                            <Link
                                target="_blank"
                                style={{ fontFamily: 'Encode, sans-serif' }}
                                href="/handelsbetingelser">Jeg er enig i Geekster.dk*
                            </Link>
                        </div>
                        <ThemeProvider theme={buttonTheme}>
                            <div>
                                <Button
                                    className={buttonClasses.authButton}
                                    variant="contained"
                                    color="primary"
                                    disabled={!acceptedTaC
                                        || !email || !firstName || !lastName || !password}
                                    onClick={signUp}>
                                    Opret profil
                                </Button>
                            </div>
                        </ThemeProvider>
                    </SignUpForm>
                )}
                {/* {signUpView === 1 && (
                    <SignUpForm 
                        showSubscriptionOptions={showSubscriptionOptions}
                        selectedPricePlan={selectedPricePlan}
                        setSelectedPricePlan={setSelectedPricePlan}
                        onCouponClick={() => history.push('/skoler')}>
                        <TextInputTemplate value={email} onChange={setEmail} label="Email" />

                        <TextInputTemplate value={school} onChange={setSchool} label="Skoler" />
                        <TextInputTemplate
                            value={password}
                            label="Password"
                            onChange={setPassword}
                            type="password" />

                        <TextInputTemplate
                            value={repeatPassword}
                            onChange={setRepeatPassword}
                            label="Gentag Password"
                            type="password" />

                        <div className={tableClasses.divInputRow}>
                            <TextInputTemplate
                                value={firstName}
                                onChange={setFirstName}
                                label="Fornavn" />

                            <TextInputTemplate
                                value={lastName}
                                onChange={setLastName}
                                label="Efternavn" />
                        </div>
                        <TextInputTemplate
                            value={coupon}
                            onChange={setCoupon}
                            label="Kupon" />
                        <div className={tableClasses.checkBox}>
                            <Checkbox
                                checked={acceptedTaC}
                                onChange={(e) => setAcceptedTaC(e.target.checked)}
                                inputProps={{ 'aria-label': 'primary checkbox' }} />
                            <Link
                                target="_blank"
                                style={{ fontFamily: 'Encode, sans-serif' }}
                                href="/handelsbetingelser">Jeg er enig i Geekster.dk*
                            </Link>
                        </div>
                        <ThemeProvider theme={buttonTheme}>
                            <div>
                                <Button
                                    className={buttonClasses.authButton}
                                    variant="contained"
                                    color="primary"
                                    disabled={!acceptedTaC
                                                    || !email || !firstName || !lastName || !password}
                                    onClick={signUp}>
                                    Opret profil
                                </Button>
                            </div>
                        </ThemeProvider>
                    </SignUpForm>
                )}
                {signUpView === 2 && (
                    <SignUpForm 
                        showSubscriptionOptions={showSubscriptionOptions}
                        selectedPricePlan={selectedPricePlan}
                        setSelectedPricePlan={setSelectedPricePlan}
                        onCouponClick={() => history.push('/skoler')}>
                        <TextInputTemplate value={email} onChange={setEmail} label="Email" />

                        <TextInputTemplate value={school} onChange={setSchool} label="Skoler" />
                        <div className={tableClasses.divInputRow}>
                            <TextInputTemplate
                                value={firstName}
                                onChange={setFirstName}
                                label="Fornavn" />

                            <TextInputTemplate
                                value={lastName}
                                onChange={setLastName}
                                label="Efternavn" />
                        </div>
                        <TextInputTemplate
                            value={coupon}
                            onChange={setCoupon}
                            label="Kupon" />
                        <div className={tableClasses.checkBox}>
                            <Checkbox
                                checked={acceptedTaC}
                                onChange={(e) => setAcceptedTaC(e.target.checked)}
                                inputProps={{ 'aria-label': 'primary checkbox' }} />
                            <Link
                                target="_blank"
                                style={{ fontFamily: 'Encode, sans-serif' }}
                                href="/handelsbetingelser">Jeg er enig i Geekster.dk*
                            </Link>
                        </div>
                        <ThemeProvider theme={buttonTheme}>
                            <div>
                                <Button
                                    className={buttonClasses.authButton}
                                    variant="contained"
                                    color="secondary"
                                    disabled={!acceptedTaC || !email || !firstName || !lastName}
                                    onClick={signUpWithUniLogin}>
                                    Opret profil med UniLogin
                                </Button>
                            </div>
                        </ThemeProvider>
                    </SignUpForm>
                )} */}
            </Body>
        </div>
    )
}
