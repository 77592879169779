import { API } from 'aws-amplify'

export const submitContactForm = async (email: string, name: string, message: string) => {
    return API.post('openapi', '/contact', {
        body: {
            email,
            name,
            message
        }
    })
}
