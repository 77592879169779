import { API } from 'aws-amplify'

import { LibraryFilter, PDFType } from '../../types/types'

export const listPDFs = async (): Promise<PDFType[]> => {
    const pdf = await API.get('api', '/pdf', {})
    return pdf
}

export const filterPDFs = async (filterParams: LibraryFilter): Promise<PDFType[]> => {
    const pdf = await API.post('api', '/pdf/filter', {
        body: filterParams
    })
    return pdf
}
