import { makeStyles } from '@material-ui/core/styles'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const pageStyles = makeStyles((theme) => ({
    bgImage: {
        maxWidth: 300,
        position: 'absolute',
        right: 30,
        bottom: 5,
        opacity: 0.7,
        zIndex: -3
    },
    bgImageTest: {
        maxWidth: 300,
        position: 'absolute',
        right: 30,
        top: '100%',
        bottom: 5,
        opacity: 0.7,
        zIndex: -3
    },
    gridContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    table: {
        backgroundColor: 'transparent',
        maxWidth: '70%',
        margin: 'auto',
        position: 'relative',
        zIndex: 5,
        padding: 20,
        [theme.breakpoints.down('sm')]: {
            maxWidth: '85%'
        },
    },
    homeworkItem: {
        marginBottom: 50,
        minWidth: '100%',
        backgroundColor: 'white',
        borderRadius: 25,
        padding: 20
    }
}))
