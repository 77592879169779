import { Button, Typography } from '@material-ui/core'
import * as React from 'react'

type Props = {
    onClick: () => void
    title: string
}

export const NoAuthenticatedNav: React.FC<Props> = ({ onClick, title }) => {
    return (
        <div style={{
            position: 'fixed',
            justifyContent: 'space-between',
            alignItems: 'center',
            overflow: 'hidden',
            zIndex: 1,
            width: '100%',
            top: 0,
            display: 'flex',
            flexDirection: 'row',
            marginTop: 30,
        }}>
            <div style={{ marginLeft: '1em', marginTop: '0.7em' }} />
            <div style={{ marginRight: '2em', marginTop: '0.7em' }}>
                <Button
                    style={{ marginLeft: 'auto', background: '#fff', borderColor: '#3C8CAC' }}
                    onClick={onClick}
                    variant="outlined">
                    <Typography variant="body1" style={{ color: '#3C8CAC' }}>{title}</Typography>
                </Button>
            </div>
        </div>
    )
}
