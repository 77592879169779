import {
    createStyles, Grid, makeStyles, Typography
} from '@material-ui/core'
import * as React from 'react'

import { BackgroundImage } from '../atoms/BackgroundImage'

interface Props {
    onlyPicture?: boolean
}

const useStyles = makeStyles(() => createStyles({
    bottomContent: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 0,
    },
    firstBottom: {
        backgroundColor: '#FAFAFA',
        minHeight: '12vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        paddingTop: 11,
        paddingBottom: 11
    },
    secondBottom: {
        backgroundColor: '#F3F3F3',
        minHeight: '2vh',
    },
    image: {
        position: 'relative',
        maxWidth: '28vw',
        height: 'auto',
        marginLeft: '65%',
        marginBottom: -5,
    }
}))

export const BottomBar: React.FC<Props> = ({ onlyPicture }) => {
    const classes = useStyles()
    return (
        <Grid item className={classes.bottomContent}>
            <BackgroundImage />
            {!onlyPicture && (
                <>
                    <div className={classes.firstBottom}>
                        <Typography align="center" variant="caption">
                            Center for God Matematikundervisning
                        </Typography>
                        <Typography align="center" variant="caption">
                            info@geekster.dk
                        </Typography>
                        <Typography align="center" variant="caption">
                            CVR: 8888888
                        </Typography>
                    </div>
                    <div className={classes.secondBottom}>
                        <Typography align="center" variant="body2">
                            Copyright @ Geekster.dk
                        </Typography>
                    </div>
                </>
            )}
        </Grid>
    )
}
