import {
    Button,
    createStyles,
    Dialog, DialogActions, DialogTitle, makeStyles, ThemeProvider
} from '@material-ui/core'
import * as React from 'react'

import { buttonTheme } from '../../utils/styles'
import { drawerWidth } from '../wrappers/BodyWithTopBar'

const useStyles = makeStyles((theme) => createStyles({
    centeredDialog: {
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
        },
        marginLeft: drawerWidth - 5,
        zIndex: 1301
    }
}))

interface Props {
    text: string,
    open: boolean,
    onCancel: () => void,
    onConfirm: (params?: any) => void,
}

const ConfirmDialog: React.FC<Props> = ({
    text,
    onCancel,
    onConfirm,
    open,
}) => {
    const classes = useStyles()
    return (

        <Dialog
            open={open}
            onClose={onCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={classes.centeredDialog}>
            <DialogTitle id="alert-dialog-title">{text}</DialogTitle>
            <DialogActions>
                <ThemeProvider theme={buttonTheme}>
                    <Button onClick={onCancel}>
                        Nej
                    </Button>
                    <Button onClick={onConfirm} color="secondary">
                        Ja, helt sikker
                    </Button>
                </ThemeProvider>
            </DialogActions>
        </Dialog>
    )
}
export default ConfirmDialog
