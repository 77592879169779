import { makeStyles } from '@material-ui/core/styles'

export function getModalStyle() {
    const top = 50
    const left = 50
    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    }
}
export const modalStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        outline: 0
    },
    paper: {
        position: 'absolute',
        outline: 0,
        width: '53.5vw',
        backgroundColor: '#F1ECE8',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    paperBigger: {
        position: 'absolute',
        borderRadius: 8,
        outline: 0,
        width: '70vw',
        backgroundColor: '#F1ECE8',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    FullPage: {
        flexGrow: 1,
        overflow: 'scroll',
        flexWrap: 'wrap',
        width: '100%',
        height: '100%',
        position: 'absolute',
        borderRadius: 8,
        outline: 0,
        backgroundColor: '#f8f8f8',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}))

export const answerModalStyles = makeStyles(() => ({
    title: {
        textAlign: 'center',
        fontSize: 18,
        padding: 20,
        fontStyle: 'italic',
        fontWeight: 600
    },
    answer: {
        flexWrap: 'wrap',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: 'auto',
        maxWidth: 90,
    },
    answer1: {
        flexWrap: 'wrap',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        margin: 'auto',
    },
}))
