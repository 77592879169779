import { Chip, Typography } from '@material-ui/core'
import * as React from 'react'

interface Props {
    selected: string[],
    selectText: string
}

const FilterValuePlaceholder: React.FC<Props> = ({ selected, selectText }) => {
    return (
        <div style={{
            display: 'flex',
            marginLeft: '5%',
            marginRight: '5%',
            alignItems: 'center',
            justifyContent: 'space-around'
        }}>
            <Typography>{selectText}</Typography>
            {selected.length > 0 && (
                <Chip
                    style={{
                        fontWeight: 'bold'
                    }}
                    label={selected.length} />
            )}
        </div>
    )
}
export default FilterValuePlaceholder
