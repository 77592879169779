import React, { useCallback } from 'react'

export function useLoadingManager() {
    const [loading, setLoading] = React.useState<boolean>(false)

    const startLoading = useCallback(() => {
        setLoading(true)
    }, []) 

    const stopLoading = useCallback(() => {
        setLoading(false)
    }, []) 

    return {
        loading,
        startLoading,
        stopLoading
    }
}
