import {
    Divider, List, ListItem, TablePagination
} from '@material-ui/core'
import * as React from 'react'

import Alert from '../atoms/Alert'
import { LibraryContactForm } from './LibraryContactForm'
import PDFListItem from './PDFListItem'
import QuizListItem from './QuizListItem'
import VideoListItem from './VideoListItem'

interface Props {
    data: any[],
    typeOfLibrary: 'quiz' | 'video' | 'communityquiz' | 'pdf'
}

const PaginatedList: React.FC<Props> = ({ data, typeOfLibrary }) => {
    const [page, setPage] = React.useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(5)
    const [homeworkError, setHomeworkError] = React.useState<boolean>(false)
    const [itemSuccessfullyAdded, setItemSuccessfullyAdded] = React.useState<boolean>(false)

    const currentItems = data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

    const handleChangePage = (event: any, newPage: any) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    return (
        <>
            <List>
                {data.length === 0
                    ? (
                        <ListItem>
                            <LibraryContactForm />
                        </ListItem>
                    )
                    : currentItems.map((row) => (
                        <div key={row.id}>
                            <ListItem key={row.id}>
                                {(typeOfLibrary === 'quiz' || typeOfLibrary === 'communityquiz')
                                    && (
                                        <QuizListItem
                                            isCommunity={typeOfLibrary === 'communityquiz'}
                                            item={row} />
                                    )}
                                {typeOfLibrary === 'pdf' && (
                                    <PDFListItem
                                        item={row} />
                                )}
                                {typeOfLibrary === 'video' && (
                                    <VideoListItem
                                        item={row} />
                                )}
                            </ListItem>
                            <Divider variant="inset" component="li" />
                        </div>
                    ))}
            </List>
            <TablePagination
                labelRowsPerPage="Antal pr. side"
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} af ${count !== -1 ? count : to}`}
                nextIconButtonProps={{ title: 'Næste side' }}
                backIconButtonProps={{ title: 'Forrige side' }} />
            <Alert
                text="Ingen lektier valgt!"
                setOpen={setHomeworkError}
                open={homeworkError}
                type="alert" />
            <Alert
                text="Lektie er nu oprettet."
                setOpen={setItemSuccessfullyAdded}
                open={itemSuccessfullyAdded}
                type="success" />
        </>
    )
}
export default PaginatedList
