import {
    Checkbox, Chip, FormControl, Input, ListItemText, MenuItem, Select
} from '@material-ui/core'
import * as React from 'react'

import { QuizLibraryType, VideoType } from '../../types/types'
import FilterValuePlaceholder from '../atoms/FilterValuePlaceholder'

interface Props {
    filteredItems: VideoType[] | QuizLibraryType[],
    data: string[],
    selectedItems: string[],
    onChange: (event: any) => void,
    text: 'Klassetrin' | 'Kategori' | 'Emner'
}

const FilterTemplate: React.FC<Props> = ({
    data, onChange, selectedItems, text, filteredItems
}) => {

    return (
        <FormControl style={{ minWidth: '15%' }}>
            <Select
                MenuProps={{
                    getContentAnchorEl: null,
                }}
                multiple
                displayEmpty
                value={selectedItems}
                onChange={onChange}
                input={<Input />}
                renderValue={(selected) => (
                    <FilterValuePlaceholder selected={selected as string[]} selectText={text} />
                )}>
                {data.map((name) => (
                    <MenuItem key={name} value={name}>
                        <Checkbox checked={selectedItems.indexOf(name) > -1} />
                        <ListItemText primary={name} />
                        {/* if dropdown is for topics, render the number of times
                        they appear in the filtered results */}
                        {text === 'Emner' && (
                            <Chip
                                style={{
                                    fontWeight: 'bold'
                                }}
                                label={filteredItems.map((item) => (Number(item.topics?.includes(name))))
                                    .reduce((partialSum, a) => partialSum + a, 0)} />
                        )}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}
export default FilterTemplate
