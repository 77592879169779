import { Auth, Hub } from 'aws-amplify'
import moment from 'moment'
import React from 'react'
import { BrowserRouter as Router, Switch } from 'react-router-dom'

import { Loading } from '../components/molecules/Loading'
import { NotificationPopup, Severity } from '../components/molecules/NotificationPopup'
import PaymentModal from '../components/molecules/PaymentModal'
import { TeacherType } from '../types/types'
import { channelName } from '../utils/notifications'
import Routes from '../utils/Routes'
import { useCognito } from '../utils/Services/cognito-service'
import {
    getActiveCustomerSubscriptions
} from '../utils/Services/reepay-service'
import { getById, updateTeacher } from '../utils/Services/teacher-service'
import { TeacherManagerWrapped } from '../utils/TeacherManager'
import { fireWrap } from '../utils/wrap-functions'
import { About } from './About'
import AppRoute from './AppRoute'
import { ConfirmForgotPassword } from './ConfirmForgotPassword'
import { ConfirmSignUp } from './ConfirmSignUp'
import { Contact } from './Contact'
import { GamesLibraryPage } from './GamesLibrary'
import { Help } from './Help'
import { HomePage } from './Home'
import { Login } from './Login'
import { PDFLibraryPage } from './PDFLibary'
import { PDFSharePage } from './PDFSharePage'
import { PrivacyPolicy } from './PrivacyPolicy'
import { Profile } from './Profile'
import { QuizItem } from './QuizItem'
import { QuizzLibaryPage } from './QuizLibary'
import { SignUp } from './SignUp'
import { Tac } from './TaC'
import { VideoLibaryPage } from './VideoLibary'

export default function RootRouter() {
    const { signedIn, setSignedIn } = useCognito()

    const [loading, setLoading] = React.useState(false)
    const [subscriptionPaid, setSubscriptionPaid] = React.useState(false)
    const [open, setOpen] = React.useState<boolean>(false)
    const [teacher, setTeacher] = React.useState<TeacherType>()

    const [showNotification, setShowNotification] = React.useState(false)
    const [notificationSeverity, setNotificationSeverity] = React.useState<Severity>('info')
    const [notificationMessage, setNotificationMessage] = React.useState('')

    const notificationListener = React.useCallback(({ payload: { data: { message, severity } } }) => {
        setShowNotification(true)
        setNotificationSeverity(severity)
        setNotificationMessage(message)
    }, [])

    React.useEffect(() => {
        Hub.listen(channelName, notificationListener)
        return () => Hub.remove(channelName, notificationListener)
    }, [])

    React.useEffect(() => {
        fireWrap(async () => {
            if (!signedIn) {
                setSubscriptionPaid(false)
                return
            }

            if (Auth.Credentials.Auth.user) {
                if (subscriptionPaid) {
                    return
                }

                const t = await getById()
                setTeacher(t)

                const subs = await getActiveCustomerSubscriptions(t.id)

                if (subs.length === 0) {
                    setSignedIn(false)
                    setOpen(true)
                } else {
                    if (!t.subscriptionId || t.subscriptionExpiresAt < moment().toISOString()) {
                        await updateTeacher(t.id, t.firstName, t.lastName, t.school, t.confetti,
                            subs[0].handle, subs[0].expiresAt)
                    }

                    setSubscriptionPaid(true)
                }
            }
        }, {
            operation: 'Checking Subscription',
            stateFunc: setLoading
        })

    }, [signedIn])

    if (loading) {
        return <Loading />
    }

    return (
        <>
            <NotificationPopup
                onClose={() => setShowNotification(false)}
                show={showNotification}
                severity={notificationSeverity}
                message={notificationMessage} />
            <TeacherManagerWrapped>
                <Router>
                    <Switch>
                        <AppRoute type="auth" signedIn={signedIn} exact path={Routes.Home.path}>
                            <HomePage />
                        </AppRoute>
                        <AppRoute type="auth" signedIn={signedIn} exact path={Routes.QuizLibrary.path}>
                            <QuizzLibaryPage />
                        </AppRoute>
                        <AppRoute type="auth" signedIn={signedIn} exact path={Routes.PDFLibrary.path}>
                            <PDFLibraryPage />
                        </AppRoute>
                        <AppRoute type="any" signedIn={signedIn} exact path={`${Routes.PDFShareItem.path}/:pdfPath`}>
                            <PDFSharePage />
                        </AppRoute>
                        <AppRoute type="auth" signedIn={signedIn} exact path={Routes.Quiz.path}>
                            <QuizItem />
                        </AppRoute>
                        <AppRoute type="auth" signedIn={signedIn} exact path={Routes.VideoLibrary.path}>
                            <VideoLibaryPage />
                        </AppRoute>
                        <AppRoute type="auth" signedIn={signedIn} exact path={Routes.Games.path}>
                            <GamesLibraryPage />
                        </AppRoute>
                        <AppRoute type="auth" signedIn={signedIn} exact path={Routes.Profile.path}>
                            <Profile />
                        </AppRoute>
                        <AppRoute type="auth" signedIn={signedIn} exact path={Routes.AboutGeekster.path}>
                            <About />
                        </AppRoute>
                        <AppRoute type="auth" signedIn={signedIn} exact path={Routes.HelpGeekster.path}>
                            <Help />
                        </AppRoute>
                        <AppRoute type="not-auth" signedIn={signedIn} exact path={Routes.Login.path}>
                            <Login />
                        </AppRoute>
                        <AppRoute type="not-auth" signedIn={signedIn} exact path={Routes.SignUp.path}>
                            <SignUp showSubscriptionOptions />
                        </AppRoute>
                        <AppRoute type="not-auth" signedIn={signedIn} exact path={Routes.Coupon.path}>
                            <SignUp showSubscriptionOptions={false} />
                        </AppRoute>
                        <AppRoute type="not-auth" signedIn={signedIn} exact path={Routes.ConfirmSignUp.path}>
                            <ConfirmSignUp />
                        </AppRoute>
                        <AppRoute type="not-auth" signedIn={signedIn} exact path={Routes.TaC.path}>
                            <Tac />
                        </AppRoute>
                        <AppRoute type="not-auth" signedIn={signedIn} exact path={Routes.PrivacyPolicy.path}>
                            <PrivacyPolicy />
                        </AppRoute>
                        <AppRoute type="not-auth" signedIn={signedIn} exact path={Routes.Contact.path}>
                            <Contact />
                        </AppRoute>
                        <AppRoute
                            type="not-auth"
                            signedIn={signedIn}
                            exact
                            path={Routes.ConfirmForgotPassword.path}>
                            <ConfirmForgotPassword />
                        </AppRoute>
                    </Switch>
                    {teacher && (
                        <PaymentModal
                            open={open}
                            setOpen={setOpen}
                            teacher={teacher} />
                    )}
                </Router>
            </TeacherManagerWrapped>
        </>
    )
}
