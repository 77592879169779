/* eslint-disable no-nested-ternary */
import {
    Button, createTheme, Hidden, IconButton, ThemeProvider, Toolbar
} from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar'
import CssBaseline from '@material-ui/core/CssBaseline'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import MenuIcon from '@material-ui/icons/Menu'
import ScreeRotate from '@material-ui/icons/ScreenRotation'
import { Auth, Hub } from 'aws-amplify'
import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import Logo from '../../assets/colorgeek.png'
import background from '../../assets/homeBackground.png'
import {
    LibraryDrawerRoutes, ProfileRoutes, TeacherDrawerRoutes
} from '../../utils/Routes'
import { useCognito } from '../../utils/Services/cognito-service'
import { useTeacher } from '../../utils/TeacherManager'
import { wrap } from '../../utils/wrap-functions'
import FAB from '../atoms/Fab'
import { LogoutModal } from '../molecules/LogoutModal'
import { Body } from './Body'

export const drawerWidth = 240

const buttonTheme = createTheme({
    palette: {
        primary: { main: 'rgba( 245, 245, 245, 1)' },
        secondary: { main: 'rgba( 155, 155, 155, 1)' },
        background: {
            default: '#fff'
        }
    },
})

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        display: 'fixed',
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
        },
        marginLeft: drawerWidth - 5,
        minHeight: '100vh',
    },
    appBar: {
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
            width: '100%',
        },
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        backgroundColor: 'transparent',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        // marginLeft: 0,
    },
    image: {
        width: 64,
        height: 64,
        margin: 15,
        marginLeft: 20
    },
    selectedMenuItem: {
        backgroundColor: 'orange',
        position: 'absolute',
        left: 0,
        width: 5,
        height: 23
    },
    appBarButtons: {
        marginLeft: 'auto',
        display: 'flex',
        flexDirection: 'row'
    }
}))

export const BodyWithTopBar: React.FC = ({ children }) => {

    const classes = useStyles()
    const history = useHistory()
    const location = useLocation()
    const { signedIn } = useCognito()
    const { isLoading } = useTeacher()
    const [selectedMenuItem] = React.useState<string>(location.pathname)

    const [logout, setLogout] = React.useState(false)
    const [loggingOut, setLoggingOut] = React.useState(false)
    const [mobileOpen, setMobileOpen] = React.useState(false)

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen)
    }

    const handleLogout = async () => {
        await wrap(async () => {
            await Auth.signOut()

            Hub.dispatch('auth', { event: 'signOut' }) // todo: this should be removed!
            setLoggingOut(false)

            if (localStorage.getItem('logged_in_via_uni_login') === 'true') {
                history.push('/login')
            } else {
                history.push('/login')
            }
        }, {
            operation: 'logging out',
            stateFunc: setLoggingOut
        })
    }

    const isSelected = (name: string) => selectedMenuItem === name

    const drawer = (
        <><img className={classes.image} src={Logo} alt="geekster" />
            <List>
                {TeacherDrawerRoutes.filter((item) => item.name === 'Hjem').map((item) => (
                    <ListItem
                        button
                        key={item.name}
                        onClick={() => history.push(item.path, { className: undefined })}>
                        {isSelected(item.path)
                            && <div className={classes.selectedMenuItem} />}
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <ListItemText primary={item.name} />
                    </ListItem>
                ))}
            </List><Divider />
            <List>
                {LibraryDrawerRoutes.map((item) => ((
                    <ListItem button key={item.name} onClick={() => history.push(item.path)}>
                        {isSelected(item.path)
                            && <div className={classes.selectedMenuItem} />}
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <ListItemText primary={item.name} />
                    </ListItem>
                )))}
            </List>
            <Divider />
            {ProfileRoutes.filter((item) => item.name !== 'Mine quizzer').map((item) => (
                <ListItem button key={item.name} onClick={() => history.push(item.path)}>
                    {isSelected(item.path)
                        && <div className={classes.selectedMenuItem} />}
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.name} />
                </ListItem>
            ))}
        </>
    )

    return (
        <Body>

            <div className={classes.root}>
                <CssBaseline />
                <>
                    <AppBar position="fixed" className={classes.appBar} elevation={0}>
                        <Toolbar>
                            <Hidden
                                className="teste"
                                mdUp>
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    edge="start"
                                    onClick={handleDrawerToggle}>
                                    <MenuIcon />
                                </IconButton>
                            </Hidden>
                            <FAB Icon={ScreeRotate} />
                            <div className={classes.appBarButtons}>
                                <ThemeProvider theme={buttonTheme}>
                                    {signedIn ? (
                                        <>
                                            <a
                                                style={{ textDecoration: 'none' }}
                                                href="https://nedtaelling.geekster.dk/"
                                                target="_blank"
                                                rel="noreferrer">
                                                <Button
                                                    color="primary">

                                                    Nedtælling
                                                </Button>
                                            </a>

                                            <a
                                                style={{ textDecoration: 'none' }}
                                                href="https://spilleplade.geekster.dk/"
                                                target="_blank"
                                                rel="noreferrer">
                                                <Button
                                                    color="primary">

                                                    Spilleplader
                                                </Button>
                                            </a>

                                            <Button onClick={() => history.push('/help')} color="primary">
                                                Hjælp
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                onClick={() => setLogout(true)}
                                                color="secondary"
                                                style={{ background: '#fff' }}>
                                                LOG UD
                                            </Button>
                                        </>
                                    )
                                        : (
                                            <>
                                                <Button onClick={() => history.push('/')} color="primary">
                                                    BUY ACCESS
                                                </Button>
                                                <Button onClick={() => history.push('/about')} color="primary">
                                                    ABOUT GEEKSTER
                                                </Button>
                                                <Button onClick={() => history.push('/')} color="primary">
                                                    PRICES
                                                </Button>
                                                <Button href="#text-buttons" color="primary">
                                                    TRY VIDEOS, QUIZZES AND GAMES
                                                </Button>
                                                <Button
                                                    variant="outlined"
                                                    onClick={() => setLogout(true)}
                                                    color="secondary"
                                                    style={{ background: '#fff' }}>
                                                    LOG UD
                                                </Button>
                                            </>
                                        )}
                                </ThemeProvider>
                            </div>
                        </Toolbar>
                    </AppBar>
                    <Hidden mdUp={false}>
                        <Drawer
                            className={classes.drawer}
                            open={mobileOpen}
                            variant="temporary"
                            onClose={handleDrawerToggle}
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            ModalProps={{
                                keepMounted: true, // Better open performance on mobile.
                            }}
                            anchor="left">
                            {!isLoading && drawer}
                        </Drawer>
                    </Hidden>
                    <Hidden smDown mdUp={false}>
                        <Drawer
                            className={classes.drawer}
                            open
                            variant="permanent"
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            anchor="left">
                            {!isLoading && drawer}
                        </Drawer>
                    </Hidden>
                </>
                <main
                    className={classes.content}>
                    <div className={classes.drawerHeader} />
                    {children}
                </main>
                <LogoutModal
                    disableAccept={loggingOut}
                    onConfirmAction={handleLogout}
                    onClose={() => setLogout(true)}
                    onCancelAction={() => setLogout(false)}
                    isOpen={logout}
                    headerText="Er du sikker på at du vil logge ud?"
                    confirmActionButtonText="Ja, helt sikker"
                    cancelActionButtonText="Nej" />
            </div>
        </Body>
    )
}
