import { API } from 'aws-amplify'

import {
    ClassType,
    CreateTeacherUserType,
    CreateUniLoginAuthIntentType,
    CreateUniLoginSignUpIntentType, DelegatedCognitoLogin, FinalizeUniLoginAuthIntentType,
    FinalizeUniLoginSignUpIntentType,
    HomeworkType,
    StudentType, TeacherSignUpDetailsType,
    TeacherType,
    UniLoginAuthIntentType
} from '../../types/types'

export const initiateSignUpTeacherWithUniLogin = async (details: CreateTeacherUserType, returnUrl: string) => {
    const body: CreateUniLoginSignUpIntentType = {
        returnUrl,
        payload: details,
        group: 'teacher'
    }

    return await API.post('openapi', '/unilogin/signup', {
        body
    }) as UniLoginAuthIntentType
}

export const finalizeSignUpTeacherWithUniLogin = async (params: FinalizeUniLoginSignUpIntentType) => {
    return await API.put('openapi', '/unilogin/signup', {
        body: params
    }) as TeacherType
}

export const initiateLogInTeacherWithUniLogin = async (returnUrl: string) => {
    const body: CreateUniLoginAuthIntentType = {
        returnUrl,
        group: 'teacher'
    }

    return await API.post('openapi', '/unilogin/login', {
        body
    }) as UniLoginAuthIntentType
}

export const finalizeLogInTeacherWithUniLogin = async (params: FinalizeUniLoginAuthIntentType) => {
    return await API.put('openapi', '/unilogin/login', {
        body: params
    }) as DelegatedCognitoLogin
}

export const signUpTeacher = async (params: CreateTeacherUserType) => {
    return await API.post('openapi', '/teachers/signup', {
        body: params
    }) as TeacherType
}

export const getTeacherSignUpDetails = async (email: string) => {
    return await API.get('openapi', `/teachers/signup/${email}`, {}) as TeacherSignUpDetailsType
}

export const updateTeacher = async (
    teacherId: string,
    firstName: string,
    lastName: string,
    school: string,
    confetti: boolean,
    subscriptionId?: string,
    subscriptionExpiresAt?: string
) => {
    return await API.put('api', `/teachers/${teacherId}`, {
        body: {
            firstName, lastName, school, subscriptionId, subscriptionExpiresAt, confetti
        }
    }) as TeacherType
}

export const getById = async (): Promise<TeacherType> => {
    return API.get('api', '/teachers/me', {})
}

export const getIsParent = async (): Promise<boolean> => {
    return API.get('api', '/teachers/is-parent', {})
}

export const getClassesByTeacherId = async (): Promise<ClassType[]> => {
    return API.get('api', '/teachers/classes', {})
}

export const getStudentsByTeacherId = async (): Promise<StudentType[]> => {
    return API.get('api', '/teachers/students', {})
}

export const getHomeworksByTeacherId = async (): Promise<HomeworkType[]> => {
    return API.get('api', '/teachers/homeworks', {})
}

export const deleteMyself = async () => {
    return API.del('api', '/teachers/me', {})
}
