import {
    Button, Grid, Paper, TableContainer, Typography
} from '@material-ui/core'
import * as React from 'react'

import { QuizLibraryType, VideoType } from '../../types/types'
import {
    gradeLevels, indskolingGradeLevels, mellemtrinGradeLevels, udskolingGradeLevels
} from '../../utils/globalData'
import { filterLibrary, getGradesByKeyword } from '../../utils/helper'
import { useLoadingManager } from '../../utils/hooks'
import { filterPDFs, listPDFs } from '../../utils/pdf-service'
import {
    filterCommunityQuizzes,
    listPublicCommunityQuizzes
} from '../../utils/Services/community-quiz-service'
import { filterQuizzes, listQuizzes } from '../../utils/Services/quiz-service'
import { filterVideos, listVideos } from '../../utils/Services/video-service'
import { useTableStyles } from '../../utils/styles'
import { BottomBar } from '../molecules/BottomBar'
import FilterTemplate from '../molecules/FilterTemplate'
import { Loading } from '../molecules/Loading'
import { SearchBarContainer } from '../molecules/SearchBarContainer'

interface Props {
    items: VideoType[] | QuizLibraryType[],
    setRows: (items: VideoType[] | QuizLibraryType[]) => void,
    headerText: string,
    listOfTopics: string[],
    listOfCategories: string[],
    searchFunction: (searchInput: string) => void,
    searchValue: string,
    typeOfLibrary: 'video' | 'quiz' | 'communityquiz' | 'pdf'
}

const LibraryScreen: React.FC<Props> = ({
    children, setRows, headerText, searchFunction, searchValue, typeOfLibrary,
    listOfTopics, listOfCategories, items
}) => {
    const classes = useTableStyles()
    const { loading, startLoading, stopLoading } = useLoadingManager()
    const [selectedCategories, setSelectedCategories] = React.useState<string[]>([])
    const [selectedGrades, setSelectedGrades] = React.useState<string[]>([])
    const [selectedTopics, setSelectedTopics] = React.useState<string[]>([])
    const [showMyPrivate, setShowMyPrivate] = React.useState<boolean>(false)

    React.useEffect(() => {
        (async () => {
            startLoading()
            if (typeOfLibrary === 'video') await onVideoFilterChange()
            if (typeOfLibrary === 'pdf') await onPDFFilterChange()
            if (typeOfLibrary === 'quiz') await onQuizzFilterChange()
            if (typeOfLibrary === 'communityquiz') await onCommunityQuizzFilterChange()
            stopLoading()
        })()
    }, [selectedCategories, selectedGrades, selectedTopics, searchValue, showMyPrivate])

    const onVideoFilterChange = async () => {
        await filterLibrary(selectedCategories, selectedGrades, selectedTopics, searchValue,
            listVideos, filterVideos, setRows)
    }

    const onQuizzFilterChange = async () => {
        await filterLibrary(selectedCategories, selectedGrades, selectedTopics, searchValue,
            listQuizzes, filterQuizzes, setRows)
    }

    const onPDFFilterChange = async () => {
        await filterLibrary(selectedCategories, selectedGrades, selectedTopics, searchValue,
            listPDFs, filterPDFs, setRows)
    }

    const onCommunityQuizzFilterChange = async () => {
        await filterLibrary(selectedCategories, selectedGrades, selectedTopics, searchValue,
            listPublicCommunityQuizzes, filterCommunityQuizzes, setRows, showMyPrivate)
    }

    const cancelSearch = () => {
        // setSelectedFitlers('')
        searchFunction('')
    }

    const handleChange = (event: any, setFunction: (input: any) => void) => {
        if (event.target.value.length === 0) setFunction([])
        setFunction(event.target.value)
    }

    const handleGradesChange = (event: any) => {
        if (event.target.value.length === 0) setSelectedGrades([])
        else {
            let newGrades = event.target.value as string[]

            newGrades = getGradesByKeyword(event.target.value,
                newGrades, 'indskoling', indskolingGradeLevels, selectedGrades)
            newGrades = getGradesByKeyword(event.target.value,
                newGrades, 'mellemtrin', mellemtrinGradeLevels, selectedGrades)
            newGrades = getGradesByKeyword(event.target.value,
                newGrades, 'udskoling', udskolingGradeLevels, selectedGrades)

            setSelectedGrades(newGrades)
        }
    }

    return (
        <>
            <TableContainer className={classes.tableWithoutBottomButtons} component={Paper}>
                <Typography variant="h6" style={{ textAlign: 'center', marginBottom: '2%' }}>
                    {headerText}
                </Typography>
                <Grid
                    container
                    style={{
                        display: 'flex',
                        flex: 1,
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        paddingLeft: '1.5%',
                        paddingRight: '2.5%',
                    }}>
                    <FilterTemplate
                        filteredItems={items}
                        data={gradeLevels}
                        onChange={handleGradesChange}
                        text="Klassetrin"
                        selectedItems={selectedGrades} />
                    <FilterTemplate
                        filteredItems={items}
                        data={listOfCategories}
                        onChange={(e) => handleChange(e, setSelectedCategories)}
                        text="Kategori"
                        selectedItems={selectedCategories} />
                    <FilterTemplate
                        filteredItems={items}
                        data={listOfTopics}
                        onChange={(e) => handleChange(e, setSelectedTopics)}
                        text="Emner"
                        selectedItems={selectedTopics} />

                    <SearchBarContainer
                        searchComponent
                        value={searchValue}
                        onChange={(searchVal: string) => searchFunction(searchVal)}
                        onCancelSearch={() => cancelSearch()} />
                    {typeOfLibrary === 'communityquiz' && (
                        <Button
                            variant="contained"
                            onClick={showMyPrivate ? () => setShowMyPrivate(false) : () => setShowMyPrivate(true)}>
                            {showMyPrivate ? 'Vis alle offentlige' : 'Vis mine quizzer'}
                        </Button>
                    )}
                </Grid>
                {loading ? <Loading />
                    : children}
            </TableContainer>
            <BottomBar onlyPicture />
        </>
    )
}
export default LibraryScreen
