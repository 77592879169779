/* eslint-disable max-len,no-nested-ternary */
import {
    Grid, Link, ThemeProvider
} from '@material-ui/core'
import { createTheme } from '@material-ui/core/styles'
import * as React from 'react'

import ImageGaming from '../../assets/gamingbutton.png'
import ImageQuiz from '../../assets/quizbutton.png'
import ImageVideo from '../../assets/videobutton.png'
import { BottomBar } from '../../components/molecules/BottomBar'
import { Loading } from '../../components/molecules/Loading'
import { BodyWithTopBar } from '../../components/wrappers/BodyWithTopBar'
import { useTeacher } from '../../utils/TeacherManager'

interface Props {

}
const theme = createTheme({
    palette: {
        primary: {
            main: '#000000'
        },
        secondary: {
            main: '#808080'
        },
    },
    typography: {
        button: {
            textTransform: 'none',
        }
    }
})

// eslint-disable-next-line no-shadow

export const HomePage: React.FC<Props> = () => {

    const {
        isLoading: loading,
        fetchClasses, fetchHomeworks, fetchClassNames, fetchSubscriptionData
    } = useTeacher()

    const isLoading = () => {
        return fetchSubscriptionData.loading || fetchClasses.loading || fetchHomeworks.loading || fetchClassNames.loading
    }
    return (
        <BodyWithTopBar>
            {isLoading() || loading ? <Loading />
                : (
                    <ThemeProvider theme={theme}>

                        <>
                            <Grid
                                style={{
                                    maxWidth: '85%', margin: 'auto', position: 'relative', zIndex: 5
                                }}
                                container
                                spacing={2}>   
                                <Grid container item spacing={6}>
                                    <Grid item xs={12} sm={6} md={6} style={{ marginBottom: 0, paddingBottom: 0 }}>
                                        <Link href="/quizbibliotek">
                                            <img
                                                alt="quizzes"
                                                src={ImageQuiz}
                                                style={{
                                                    maxWidth: '100%', maxHeight: '50vh', marginTop: 5, marginBottom: 5, paddingRight: 10
                                                }}/>
                                        </Link>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <Link href="/videobibliotek">
                                            <img
                                                alt="videos"
                                                src={ImageVideo}
                                                style={{
                                                    maxWidth: '100%', maxHeight: '50vh', marginTop: 5, marginBottom: 5, paddingRight: 10
                                                }}/>
                                        </Link>
                                    </Grid>
                                </Grid>
                                <Grid container item spacing={6}>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <Link href="/spil">
                                            <img
                                                alt="games"
                                                src={ImageGaming}
                                                style={{
                                                    maxWidth: '100%', maxHeight: '50vh', marginTop: 5, marginBottom: 5, paddingRight: 10
                                                }}/>
                                        </Link>

                                    </Grid>
                                </Grid>
                            </Grid>
                            <BottomBar onlyPicture />
                        </>

                    </ThemeProvider>
                )}
        </BodyWithTopBar>
    )
}
