import { createStyles, createTheme, makeStyles } from '@material-ui/core'
import { green } from '@material-ui/core/colors'

import background from '../assets/GeeksterBackground.png'
import { drawerWidth } from '../components/wrappers/BodyWithTopBar'
import { libraryPaleOrange } from './colors'

export const useModalStyles = makeStyles((theme) => createStyles({
    modal: {
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0
        },
        marginLeft: drawerWidth / 2,
    },
}),)

export const buttonTheme = createTheme({
    palette: {
        primary: {
            main: green[500],
            contrastText: '#fff' // button text white instead of blac
        }
    },
    typography: {
        button: {
            textTransform: 'none',
        }
    }
})

export const useTableStyles = makeStyles(() => createStyles({
    table: {
        padding: 20,
        borderRadius: 25,
        maxWidth: '85%',
        margin: 'auto',
        marginBottom: 20,
        position: 'relative',
        zIndex: 5
    },

    table1: {
        padding: 20,
        borderRadius: 25,
        maxWidth: '85%',
        margin: 'auto',
        marginBottom: 20,
        position: 'relative',
        zIndex: 5,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    tableWithoutBottomButtons: {
        padding: 20,
        borderRadius: 25,
        maxWidth: '85%',
        margin: 'auto',
        marginBottom: '10%',
        position: 'relative',
        zIndex: 5
    },
    divButton: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingRight: 40,
        paddingLeft: 40,
    },
    divInputs: {
        display: 'flex',
        flexDirection: 'column',
        paddingRight: 30,
        paddingLeft: 30,
        marginTop: 50,
        maxWidth: '85%',
        margin: 'auto'
    },
    divInputRow: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
    },
    checkBox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    divAuthButtons: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 30
    },
    authLogoContainer: {
        display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginTop: 30
    },
    authLogoContainer2: {
        display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center',
    },
    authBackground: {
        minHeight: '100vh',
        margin: -8,
        marginTop: -30,
        marginBottom: -10,
        paddingBottom: 10,
        display: 'fixed',
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
    },
    authBackground2: {
        minHeight: '100vh',
        margin: -8,
        marginTop: -30,
        marginBottom: -10,
        paddingBottom: 10,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
    }
}),)

export const useLibraryStyles = makeStyles(() => createStyles({
    container: {
        display: 'flex', flexDirection: 'column', flex: 1
    },
    column: {
        display: 'flex', flexDirection: 'column'
    },
    firstGrid: {
        borderRadius: 10,
        paddingLeft: 10,
        '&:hover': {
            background: libraryPaleOrange,
        }
    },
    secondGrid: {
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        paddingLeft: 10,
        background: libraryPaleOrange,
        '&:hover': {
            background: libraryPaleOrange,
        },
    },
    collapse: {
        width: '100%',
        padding: 5,
        border: `8px solid ${libraryPaleOrange}`,
        borderBottomLeftRadius: 15,
        borderBottomRightRadius: 15
    },
    videoContainer: {
        display: 'flex',
        justifyContent: 'center',
        margin: 10,
        marginBottom: 15
    },
}),)
