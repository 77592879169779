import SearchBar from 'material-ui-search-bar'
import * as React from 'react'

interface Props {
    searchComponent?: boolean,
    value?: string,
    onChange?: (value: string) => void,
    onCancelSearch?: () => void,
}

export const SearchBarContainer: React.FC<Props> = ({
    searchComponent,
    onChange,
    onCancelSearch,
    value,
    children
}) => {

    return (
        <div
            style={{
                padding: 5,
                display: 'flex',
                backgroundColor: '#fff',
            }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
                {children}
            </div>
            {searchComponent && (
                <div style={{ minWidth: '15vw' }}>
                    <SearchBar
                        placeholder="Søg"
                        value={value}
                        onChange={onChange}
                        onCancelSearch={onCancelSearch} />
                </div>
            )}
        </div>
    )
}
