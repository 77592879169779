import { Typography } from '@material-ui/core'
import React from 'react'

import { ContactForm } from './ContactForm'

export function LibraryContactForm() {
    return (
        <div style={{ flexDirection: 'column', width: '80%', margin: '0 auto' }}>
            <Typography
                style={{
                    textAlign: 'center', marginTop: 10 
                }}
                variant="h4">
                Hovsa - der mangler vist noget indhold på Geekster!
            </Typography>
            <Typography style={{
                textAlign: 'center',
                marginTop: 10,
                marginBottom: 10
            }}>
                Skriv gerne til mig og så skal jeg nok få lavet noget til dig, 
                og skriv også meget gerne hvis du har andre gode idéer til quizzer eller videoer.
                Mvh Jesper
            </Typography>
            <ContactForm />
        </div>
    )
}
