import { Button, Modal, Typography } from '@material-ui/core'
import { Auth } from 'aws-amplify'
import React from 'react'

import { TextInputTemplate } from '../../components/molecules/TextInputTemplate'
import { buttonStyles } from '../../styles/ButtonsStyles'
import { modalStyles } from '../../styles/ModalStyles'
import { showMessage } from '../../utils/notifications'

export const ForgotPassModal: React.FC = () => {

    const [email, setEmail] = React.useState('')
    const [modalVisible, setModalVisible] = React.useState(false)
    const [emailSent, setEmailSent] = React.useState<boolean>(false)
    const [busy, setBusy] = React.useState<boolean>(false)

    const [confirmationCode, setConfirmationCode] = React.useState('')
    const [password, setPassword] = React.useState('')
    const [confirmPassword, setConfirmPassword] = React.useState('')
    const classes = modalStyles()
    const buttonClasses = buttonStyles()

    const modalOpen = () => {
        setModalVisible(true)
    }

    const submit = async () => {
        if (busy) return
        setBusy(true)
        try {
            if (email.length < 4) {
                showMessage('Email cannot be empty', 'error')
            }

            if (!emailSent) {
                await Auth.forgotPassword(email)
                setEmailSent(true)
            } else {
                if (confirmationCode.length !== 6) {
                    showMessage('Confirmation Code should be 6 characters long', 'error')
                }
                if (password.length < 6 || confirmPassword.length < 6) {
                    showMessage('Password should be at least 6 characters long', 'error')
                }
                if (password !== confirmPassword) {
                    showMessage('Passwords don\'t match', 'error')
                }

                await Auth.forgotPasswordSubmit(email, confirmationCode, password)
                await Auth.signIn(email, password)
            }
        } catch (error) {
            showMessage('error resetting password', error)

        } finally { setBusy(false) }
    }

    const closeModal = () => setModalVisible(false)

    return (
        <>

            <Modal BackdropProps={{ invisible: true }} className={classes.FullPage} open={modalVisible}>
                <div style={{ overflow: 'hidden' }}>
                    <div style={{ width: '95%', justifyContent: 'flex-end' }}>
                        <Button onClick={closeModal} />
                    </div>
                    <div style={{
                        display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'
                    }}>
                        <Typography variant="h3">Glemt kodeord?</Typography>
                        <Typography variant="h6">
                            Ingen problemer! Indtast din registrerede e-mail nedenfor,
                            så sender vi dig yderligere instruktioner.
                        </Typography>
                    </div>
                    <div style={{ marginRight: 50 }}>
                        {!emailSent && (
                            <TextInputTemplate
                                label="Indtast din e-mail"
                                onChange={setEmail}
                                value={email} />
                        )}

                        {emailSent && (
                            <>
                                <TextInputTemplate
                                    label="Indtast din bekræftelseskode"
                                    onChange={setConfirmationCode}
                                    value={confirmationCode} />

                                <TextInputTemplate
                                    type="password"
                                    label="Indtast din nye adgangskode"
                                    onChange={setPassword}
                                    value={password} />

                                <TextInputTemplate
                                    type="password"
                                    label="Bekræft ny adgangskode"
                                    onChange={setConfirmPassword}
                                    value={confirmPassword} />
                            </>
                        )}
                        <Button
                            style={{ marginLeft: 15 }}
                            className={buttonClasses.forgotPassButton}
                            onClick={submit}>{emailSent ? 'Nulstil' : 'Send Email'}
                        </Button>
                    </div>
                </div>
            </Modal>
            <Button style={{ margin: '0 auto' }} onClick={modalOpen}>Glemt kodeord?</Button>
        </>
    )
}
