import {
    Paper, TableContainer, Typography
} from '@material-ui/core'
import * as React from 'react'

import { BottomBar } from '../../components/molecules/BottomBar'
import { Loading } from '../../components/molecules/Loading'
import { useTableStyles } from '../../utils/styles'

export const Tac: React.FC = () => {
    const tableClasses = useTableStyles()
    const [loading] = React.useState<boolean>(false)

    return (
        <>
            {loading ? <Loading />
                : (
                    <TableContainer className={tableClasses.table} component={Paper}>
                        <Typography
                            variant="h5"
                            style={{ textAlign: 'center', fontWeight: 500 }}>
                            Handelsbetingelser
                        </Typography>
                        <Typography
                            variant="h5"
                            style={{ textAlign: 'center', fontWeight: 700 }}>
                            GEEKSTER.DK
                        </Typography>
                        <p>
                            <i>Nærværende handelsbetingelser finder anvendelse på køb af digitalt p
                                rodukt foretaget på Geekster.dk,
                                medmindre andet angives skriftligt. Alle aftaler indgås på dansk.
                            </i>
                        </p>
                        <p><strong>1. Køb af adgang til digitale produkter</strong></p>
                        <p>
                            1.1 Brugere af Geekster.dk får ved oprettelse adgang til alle tilgængelige
                            undervisningsvideoer på hjemmesiden. <br/>
                            1.2 ”Brugeren” er den fysiske eller juridiske person, der indgår
                            aftale med Geekster.dk, om det er en
                            enkeltperson, skole/kommune eller helt anden type bruger. <br/>
                            1.3 Før en bruger kan få adgang til undervisningsvideoerne, skal brugeren
                            oprette et login til Geekster.dk.
                            I denne forbindelse er det nødvendigt, at brugeren oplyser
                            sin e-mailadresse samt angiver et kodeord.<br/>
                            1.4 Ved brugerens oprettelse af login til Geekster.dk, skal brugeren vælge,
                            om brugeren ønsker at købe adgang til udvalget af undervisningsvideoer
                            for henholdsvis en måned eller et år ad gangen.<br/>
                            1.5 Så snart login til Geekster.dk er oprettet og brugerens betaling registreret,
                            har brugeren adgang til undervisningsvideoerne på Geekster.dk i
                            den periode, der er betalt for.<br/>
                        </p>
                        <p><strong>2. Betaling</strong></p>
                        <p>
                            2.1 Betalingen foregår på Geekster.dk og kan ske med henholdsvis Dankort,
                            VISA, MasterCard og MobilePay.
                            Brugeren får trukket sine penge fra kontoen, så snart betalingen er gennemført,
                            og brugeren vil herefter have adgang til det købte materiale.<br/>
                            2.2 I tilfælde af, at brugeren handler på vegne af en skole,
                            vil brugeren modtage en EAN-faktura fra Geekster.dk og brugerens adgang til Geekster.dk’s
                            materiale vil være tilgængeligt, når Geekster.dk har registreret betalingen.<br/>
                            2.3 Alle beløb vil forekomme i danske Kroner og er inklusive moms og afgifter.
                            Der opkræves ikke betalingsgebyr hos brugeren, men Geekster.dk tager
                            forbehold for afgiftsændringer, trykfejl samt pris- og valutaændringer.<br/>
                            2.4 Alle betalingstransaktioner, som foregår igennem Geekster.dk,
                            bliver kørt igennem en godkendt betalingsserver, der krypterer brugerens
                            kortoplysninger med SSL (Secure Socket Layer) protokol.
                            Dette betyder, at brugeren er sikret optimal sikkerhed,
                            når brugeren handler hos Geekster.dk, da brugerens informationer
                            er beskyttet mod at blive aflæst.<br/>
                            2.5 Geekster.dk benytter Reepay som betalings-getaway,
                            der er certificeret efter internationale standarder for databeskyttelse.
                            2.6 Brugerens betaling med betalingskort sker i overensstemmelse
                            med gældende love og regler for online betaling i Danmark. I tilfælde af at en
                            tredjeperson misbruger brugerens betalingskort, hæfter
                            brugeren ikke selv for misbruget.
                            Brugeren kan alene komme til at hæfte for et sådant misbrug,
                            hvis brugeren selv har overtrådt reglerne for brug af betalingskort.<br/>
                        </p>
                        <p><strong>3. Levering</strong></p>

                        <p>
                            3.1 Levering til brugeren sker straks efter en bestilling er
                            gennemført og registrering af gyldigt betalingsmiddel er registreret.<br/>
                            3.2 I tilfælde af, at brugeren er en skole eller en institution,
                            vil leveringen først ske, ved Geekster.dk’s registrering af betalingen via EAN-faktura.<br/>
                        </p>
                        <p><strong>4. Fortrydelsesret</strong></p>

                        <p>
                            4.1 Brugeren har 14 kalenderdages fortrydelsesret i tilfælde af, at brugeren skulle
                            fortryde sit køb hos Geekster.dk. Fristen løber fra den dag, hvor brugerens
                            bestilling bliver gennemført.<br/>
                            4.2 Det er alene private forbrugere, der handler hos Geekster.dk, som omfattes
                            af fortrydelsesretten i pkt. 4.1. Erhvervsdrivende og institutioner har ikke en
                            fortrydelsesret, som kan gøres gældende i tilfælde af fortrydelse på køb.<br/>
                            4.3Geekster.dk skal have modtaget meddelelse om, at brugeren ønsker at benytte sig
                            af fortrydelsesretten inden udløbet af de 14 dage. Meddelelse skal ske via e-mail
                            til info@geekster.dk og det skal i meddelelsen utvetydigt angives, at brugeren
                            ønsker at gøre brug af sin fortrydelsesret.<br/>
                            4.4 I tilfælde af, at Geekster.dk godkender brugerens fortrydelse, tilbagebetaler
                            Geekster.dk det betalte beløb til brugeren senest 3
                            dage efter fortrydelsens godkendelse.<br/>
                        </p>

                        <p><strong>5. Reklamation</strong></p>

                        <p>5.1 Geekster.dk yder brugeren 2 års garanti i henhold til Købeloven. Garantien
                            indebærer reklamation over fejl og mangler på produktet.<br/>
                            5.2 Undtaget fra reklamationsretten er fejl og skader begået
                            ved forkert betjening eller brug af produktet.<br/>
                            5.3 I tilfælde af, at brugerens reklamation er berettiget, udbedrer Geekster.dk
                            manglerne for egen regning i det omfang, det kan anses for rimeligt.<br/>
                            5.4 Brugeren skal beskrive reklamationen så detaljeret som muligt,
                            så Geekster.dk kan vurdere berettigelsen af denne. I modsat fald kan det betyde,
                            at brugeren må forvente en længere svartid.<br/>
                        </p>
                        
                        <p><strong>6. Persondataret</strong></p>

                        <p>
                            6.1 Platformen, Geekster.dk, er ikke lavet med formål om at behandle
                            personhenførbare oplysninger, hvorfor Geekster.dk opfordrer til, at brugerne ikke
                            benytter personhenførbare oplysninger ved nogen som helst færden på Geekster.dk.<br/>
                            6.2 Opfordringen fra Geekster.dk kommer af, at behandlinger af personoplysninger
                            kræver hjemmel efter databeskyttelsesreglerne og i tilfælde af manglende hjemmel,
                            kan der var tale om en krænkelse af de registreredes rettigheder,
                            som kan udløse en sanktion.<br/>
                            6.3 Geekster.dk opfordrer særligt til, at brugerne ikke angiver
                            personfølsomme oplysninger på Geekster.dk, som er omfattet af
                            databeskyttelsesforordningens art. 9, da disse er oplysninger,
                            som nyder særlig beskyttelse efter databeskyttelsesreglerne.<br/>
                            6.4 Hvis brugeren har været skyld i, at der kommer en kritik,
                            sanktion eller bødeforlæg grundet brud på databeskyttelsesreglerne, accepterer brugeren,
                            at ikke at inddrage Geekster ApS, men at brugeren er alene ansvarlig.<br/>
                        </p>

                        <p><strong>7. Ansvarsfraskrivelse</strong></p>
                        <p>
                            7.1 Når du uploader indhold til en quiz på Geekster.dk, erklærer du, at du er
                            berettiget til at anvende og uploade det pågældende indhold, og at du ved at uploade
                            indholdet, giver Geekster ApS en uindskrænket ret til at lade brugere af geekster.dk
                            tilgå og bruge din quiz. Brugerne er enten brugere, du selv har inviteret til quizzen,
                            eller – hvis du har valgt at din quiz skal være offentlig –
                            andre brugere af Geekster.dk.<br/>
                            7.2 Du indestår for, at du har de fulde rettigheder til at uploade alt indhold på
                            geekster.dk, herunder videoer, tekster, musik eller andet, og at
                            dette indhold ikke krænker nogen lovgivning eller tredjemands rettigheder, herunder
                            immaterielle rettigheder, ophavsrettigheder, retten til eget billede m.v. Du indestår
                            endvidere for, at du ikke anvender og uploader personhenførbare oplysninger
                            om personer i strid med persondatareglerne.<br/>
                            7.3 Geekster ApS forbeholder sig ret til uden varsel eller ansvar over for dig eller
                            andre at fjerne uploadet indhold, hvis der opstår mistanke eller viden om,
                            at indholdet krænker tredjemands rettigheder.<br/>
                        </p>

                        <p><strong>8. Klagemuligheder</strong></p>

                        <p>
                            8.1 Brugeren kan klage over et produkt køb hos Geekster.dk, ved at rette henvendelse til
                            Geekster.dk på telefonnummer 25 37 33 58 eller på e-mail info@geekster.dk.<br/>
                            8.2 I tilfælde af at brugeren og Geekster.dk ikke kan blive enige om en løsning på
                            problemet, kan brugeren rette henvendelse til Konkurrence- og Forbrugerstyrelsens Center
                            for Klageløsning Carl Jacobsens Vej 35 2500 Valby. Link: https://www.forbrug.dk. Læs
                            også mere om klagemulighederne her:
                            {/* eslint-disable-next-line max-len */}
                            https://www.forbrug.dk/klagemuligheder/klager-over-koeb-i- danmark/saadan-starter-duen-klagesag/.<br/>
                            8.3 Hvis brugeren er forbruger, som har bopæl i et andet EU-land, kan
                            brugeren indgive sin klage til EU-kommissionens online klageplatform på:
                            https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage.
                            Brugeren skal oplyse Geekster.dk’s e-mailadresse i
                            tilfælde af indgivelsen af en klage: info@geekster.dk.
                        </p>
                    </TableContainer>
                )}
            <BottomBar onlyPicture />
        </>
    )
}
