import * as React from 'react'

import { buttonStyles } from '../../styles/ButtonsStyles'

type Props = {
    title: string
    onClick?: () => void
    backgroundColor: string
    textColor: string
    source?: any
    alt?: string
    showLogo?: boolean
    visible?: boolean
    showIcon?:boolean
}

export const AuthButton: React.FC<Props> = ({
    textColor,
    title,
    onClick, 
    backgroundColor,
    alt,
    source,
    showLogo,
    visible = true,
    showIcon
}) => {
    const buttonClasses = buttonStyles()
    return (
        <>{visible
            && (
                <button
                    style={showLogo ? {
                        backgroundColor, color: textColor, paddingLeft: '6px', paddingRight: 0 
                    } : { backgroundColor, color: textColor }}
                    className={buttonClasses.largeAuthButton}
                    onClick={onClick}
                    type="button">
                    {showIcon && source}
                    {title}
                    {showLogo && (
                        <img style={{ width: 150, height: 50 }} src={source} alt={alt}/>
                    )}
                </button>
            )}
        </>
    )
}
