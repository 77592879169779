import { makeStyles } from '@material-ui/core/styles'

import background from '../assets/homeBackground.png'
import { primaryContrast } from '../utils/colors'

export const pdfStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '100%',
        minHeight: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            maxWidth: 220
        },
    },
    heading: {
        paddingTop: 10,
        textAlign: 'center',
        margin: 'auto',
        fontSize: 22,
        fontWeight: 600,
        [theme.breakpoints.down('sm')]: {
            fontSize: 18,
            maxWidth: 220
        },
    },
    tag: {
        display: 'flex',
        flexDirection: 'row',
        color: 'white',
        textAlign: 'center',
        width: 'fit-content',
        backgroundColor: primaryContrast,
        fontSize: 14,
        fontWeight: 600,
        marginBottom: 5,
        padding: 8,
        margin: 8,
        borderRadius: 5,
        cursor: 'pointer'
    },
    question: {
        fontWeight: 600
    },
    title: {
        textAlign: 'center',
        color: '#FFF',
        fontWeight: 600,
        marginBottom: 10
    },
    paperContainer: {
        paddingBottom: 20,
        minHeight: '100vh',
        backgroundPosition: 'fixed',
        justifyContent: 'center',
        backgroundSize: 'cover',
        backgroundClip: 'padding-box',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${background})`,
        alignItems: 'center',
        flexDirection: 'column',
    },
    anchorContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10,
        marginBottom: 20
    },
    anchor: {
        fontSize: 16,
        cursor: 'pointer',
        color: '#000',
        textDecoration: 'underline',
        fontWeight: 600,
        fontFamily: 'Encode, sans-serif',
    },
    image: {
        width: 100,
        height: 100,
        marginTop: 35,
    },
    text: {
        textAlign: 'center',
        padding: 10,
    },
    wrap: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10,
        // marginBottom: 20,
    },
    supLinkContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    supAnchor: {
        color: 'black',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    videosDiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    imageQuestion: {
        maxWidth: '40vw',
        maxHeight: '40vh',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '90%',
            maxHeight: '100%',
        },
    },
    richEditorContentDiv: {
        display: 'flex',
        marginBottom: 15,
        flexDirection: 'column',
        justifyContent: 'center',
        fontFamily: 'Encode, sans-serif',
        fontSize: '14px',
        alignItems: 'center',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            maxWidth: 220,

        },
    }
}))
