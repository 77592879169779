import { Hub } from 'aws-amplify'

import { Severity } from '../components/molecules/NotificationPopup'

export const channelName = 'notification-popup'
export const showMessage = (message: string, severity?: Severity) => {
    Hub.dispatch(channelName, {
        event: 'message',
        data: {
            message,
            severity: severity || 'info'
        }
    })
}
