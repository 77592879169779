import { CircularProgress, createStyles, makeStyles } from '@material-ui/core'
import * as React from 'react'

const useStyles = makeStyles((ThemeProvider) => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignContent: 'center',
        padding: ThemeProvider.spacing(10, 0)
    },
}),)

export const Loading: React.FC = () => {
    const classes = useStyles()
    return (
        <div className={classes.root}>
            <CircularProgress />
        </div>
    )
}
