import { makeStyles } from '@material-ui/core/styles'

import { beige } from '../utils/colors'

export const buttonStyles = makeStyles((theme) => ({
    leftButton: {
        marginLeft: '7.5%',
        position: 'relative',
        zIndex: 5
    },
    otherButtons: {
        marginLeft: '2%',
        position: 'relative',
        zIndex: 5
    },
    buttonsWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    saveButton: {
        marginRight: theme.spacing(1),
        padding: theme.spacing(1),
        borderWidth: 1,
        backgroundColor: beige,
        borderColor: '#28a745',
        borderRadius: 5,
        borderStyle: 'solid',
        color: '#28a745',
        textAlign: 'center',
        '&:hover, &:focus': {
            backgroundColor: '#28a745',
            color: '#FFFFFF',
        },
        marginBottom: 10
    },
    alertButton: {
        marginRight: theme.spacing(1),
        padding: theme.spacing(1),
        borderWidth: 1,
        backgroundColor: beige,
        borderColor: '#ffc107',
        borderRadius: 5,
        borderStyle: 'solid',
        color: '#ffc107',
        textAlign: 'center',
        '&:hover, &:focus': {
            backgroundColor: '#ffc107',
            color: '#FFFFFF',
        }
    },
    cancelButton: {
        marginRight: theme.spacing(1),
        padding: theme.spacing(1),
        borderWidth: 1,
        backgroundColor: beige,
        borderColor: '#dc3545',
        borderRadius: 5,
        borderStyle: 'solid',
        color: '#dc3545',
        textAlign: 'center',
        '&:hover, &:focus': {
            backgroundColor: '#dc3545',
            color: '#FFFFFF',
        },
        marginBottom: 10
    },
    uploadButton: {
        width: '100%',
        marginRight: theme.spacing(1),
        borderWidth: 1,
        backgroundColor: beige,
        borderColor: '#063354',
        borderRadius: 5,
        borderStyle: 'solid',
        color: '#063354',
        textAlign: 'center',
        '&:hover, &:focus': {
            backgroundColor: '#063354',
            color: '#FFFFFF',
        }
    },
    download: {
        backgroundColor: '#03a9f4',
        color: 'white',
        margin: 10,
        '&:hover, &:focus': {
            backgroundColor: '#063354',
            color: '#FFFFFF',
        }
    },
    close: {
        backgroundColor: 'red',
        color: 'white',
        margin: 10,
        '&:hover, &:focus': {
            backgroundColor: 'red',
            color: '#FFFFFF',
        }
    },
    forgotPassButton: {
        margin: 0,
        borderWidth: 1,
        backgroundColor: 'white',
        borderColor: '#ffc107',
        borderRadius: 5,
        borderStyle: 'solid',
        color: '#ffc107',
        textAlign: 'center',
        '&:hover, &:focus': {
            backgroundColor: '#ffc107',
            color: '#FFFFFF',
        }
    },
    copyClipboard: {
        marginBottom: 15,
        minWidth: 'fit-content',
        cursor: 'pointer',
        marginTop: 15,
    },
    authButton: {
        position: 'relative',
        zIndex: 5
    },
    largeAuthButton: {
        width: '50%',
        minWidth: 200,
        maxWidth: 350,
        height: 55,
        borderRadius: 5,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: '#3C8CAC',
        cursor: 'pointer',
        fontSize: 20,
        float: 'left',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 10
    },
    largeUniButton: {
        width: '50%',
        minWidth: 200,
        maxWidth: 350,
        height: 55,
        borderRadius: 5,
        borderWidth: 1,
        backgroundColor: '#FFFFFF',
        borderStyle: 'solid',
        borderColor: '#3C8CAC',
        cursor: 'pointer',
        fontSize: 20,
        float: 'left',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 10,
        '&:hover, &:focus, &active': {
            backgroundColor: '#3C8CAC',
            color: '#FFFFFF',
        }
    },
}))
