import {
    Button,
    Collapse, createStyles, Grid, Link, makeStyles, Typography
} from '@material-ui/core'
import LinkIcon from '@material-ui/icons/Link'
import * as React from 'react'
import { Document, Page, pdfjs } from 'react-pdf'

import { PDFType } from '../../types/types'
import { useLibraryStyles } from '../../utils/styles'
import LibraryTextProperties from '../atoms/LibraryTextProperties'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

interface Props {
    item: PDFType,
    // setItemAdded: (successful: boolean) => void
}

const useStyles = makeStyles(() => createStyles({
    generalTags: {
        marginBottom: 10,
        marginTop: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly'
    },
}))

const PDFListItem: React.FC<Props> = ({ item }) => {
    const libraryClasses = useLibraryStyles()
    const classes = useStyles()
    const [open, setOpen] = React.useState<boolean>(false)

    const copy = async () => {
        await navigator.clipboard.writeText(`laerer.geekster.dk/del-pdf/${item.pdfUri.split('.com/')[1]}`)
    }

    return (
        <div className={libraryClasses.container}>
            <Grid
                className={open ? libraryClasses.secondGrid : libraryClasses.firstGrid}
                onClick={() => setOpen(!open)}
                container>
                <Grid item xs={12} sm={4} className={classes.generalTags}>
                    <Document
                        file={item.pdfUri}>
                        <Page pageIndex={0} height={150} />
                    </Document>
                </Grid>
                <Grid item xs={12} sm={4} className={classes.generalTags}>
                    <LibraryTextProperties headerText="Titel" smallerText={item.title} />
                    <LibraryTextProperties headerText="Kategori" smallerText={item.categories.join(', ')} />
                </Grid>
                <Grid item xs={12} sm={4} className={classes.generalTags}>
                    <LibraryTextProperties headerText="Beskrivelse" smallerText={item.description} />
                    <LibraryTextProperties
                        headerText="Klassetrin"
                        smallerText={item.grades?.join(', ') || 'Ingen'} />
                </Grid>
            </Grid>
            <Grid container>
                <Collapse
                    className={libraryClasses.collapse}
                    in={open}
                    timeout="auto"
                    unmountOnExit>
                    <Grid container>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            className={libraryClasses.container}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography variant="h5">Opgave-fil</Typography>
                                <div>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={copy}
                                        startIcon={<LinkIcon />}>
                                        <Typography
                                            variant="h5"
                                            style={{ textTransform: 'none' }}
                                            color="textPrimary">Kopier pdf-link
                                        </Typography>
                                    </Button>
                                </div>
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'center' }}>

                                <embed
                                    width="700px"
                                    height="800px"
                                    src={item.pdfUri}
                                    type="application/pdf" />
                            </div>

                            <Typography>
                                <Link
                                    target="_blank"
                                    href={item?.mainFile?.uri}>
                                    {item.mainFile?.name}
                                </Link>
                            </Typography>
                            {item.resultsFile?.uri && (
                                <div>
                                    <Typography variant="h6">Facit-fil</Typography>
                                    <Typography>
                                        <Link
                                            target="_blank"
                                            href={item?.resultsFile?.uri}>
                                            {item.resultsFile?.name}
                                        </Link>
                                    </Typography>
                                </div>
                            )}

                            {item.supplementUri?.map((l) => (
                                <Link
                                    key={l}
                                    target="_blank"
                                    href={l}
                                    rel="noreferrer"> Link til Mattips opgaver om emnet
                                </Link>
                            ))}
                        </Grid>
                    </Grid>
                </Collapse>
            </Grid>
        </div>
    )
}
export default PDFListItem
