import * as React from 'react'

import LogoImage from '../../assets/matematikgladlogo.png'

export const Logo: React.FC = () => {
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            marginTop: 30,
            alignItems: 'center'
        }}>
            <img style={{ width: 300, height: 300 }} src={LogoImage} alt="Geekster Logo" />
            {/* <Typography variant="h3">Geekster.dk</Typography> */}
        </div>
    )
}
