import { API } from 'aws-amplify'

import {
    AnswerType, QuizParentResultType, SubmitQuizParentAnswerType 
} from '../../types/types'

export const submitQuizAnswer = async (params: SubmitQuizParentAnswerType): Promise<AnswerType> => {
    return API.post('api', '/answers-parents', {
        body: params
    })
}

export const getQuizAnswerResult = async (quizId: string): Promise<QuizParentResultType> => {
    return API.post('api', '/quizzes-parents/result', {
        body: { quizId }
    })
}

export const deleteQuizAnswerResult = async (id: string) => {
    return API.del('api', `/answers-parents/${id}`, {})
}
