import { Button, Modal } from '@material-ui/core'
import * as Assert from 'assert'
import { Auth } from 'aws-amplify'
import * as React from 'react'

import PricePlanImage from '../../assets/24kr.png'
import { getModalStyle, modalStyles } from '../../styles/ModalStyles'
import { PricePlan, TeacherType } from '../../types/types'
import {
    createSubscription,
    deletePendingSubscription,
    getSubscriptionSession,
    prepareSubscription,
    validateCoupon
} from '../../utils/Services/reepay-service'
import { wrap } from '../../utils/wrap-functions'
import GoBackHeader from '../atoms/GoBackHeader'
import { TextInputTemplate } from './TextInputTemplate'

interface Props {
    open: boolean,
    teacher: TeacherType,
    setOpen: (open: boolean) => void,
}

declare const Reepay: any

const PaymentModal: React.FC<Props> = ({
    teacher, open, setOpen
}: Props) => {
    const classes = modalStyles()
    const [modalStyle] = React.useState(getModalStyle)

    const rpModalRef: any = React.useRef(null)
    const [busy, setBusy] = React.useState(false)

    const [coupon, setCoupon] = React.useState<string>(teacher.coupon || '')
    const [sessionId, setSessionId] = React.useState<string>()
    const [subscriptionId, setSubscriptionId] = React.useState<string>()
    const [selectedPrice, setSelectedPrice] = React.useState<PricePlan>('1month')

    React.useEffect(() => {
        if (sessionId) {
            rpModalRef.current = new Reepay.ModalSubscription(sessionId)
            addReepayEventHandlers()
        }
    }, [sessionId])

    React.useEffect(() => {
        setSelectedPrice('1monthParent')
    }, [])

    const submitPayment = async () => {
        Assert.ok(teacher)

        await wrap(async () => {
            if (coupon.length && !(await validateCoupon(coupon)).isValid) {
                throw Error('Coupon code is invalid.')
            }

            const coupons = coupon.length ? [coupon] : undefined

            const invokeWithCatch = async (func: any) => {
                try {
                    return await func(
                        teacher.id,
                        teacher.email,
                        teacher.lastName,
                        selectedPrice,
                        coupons
                    )
                } catch (error) {
                    return undefined
                }
            }

            if (coupon.length) {
                const sub = await invokeWithCatch(createSubscription)
                if (sub) {
                    await handleSuccessPayment()
                    return
                }
            }

            const sub = await invokeWithCatch(prepareSubscription)
            if (!sub) {
                throw Error('Failed to subscribe.')
            }

            const subscriptionSessionId = await getSubscriptionSession(sub.handle)
            setSubscriptionId(sub.handle)

            if (subscriptionSessionId) {
                setSessionId(subscriptionSessionId.id)
            }
        }, {
            operation: 'Subscribing',
            stateFunc: setBusy,
            showSuccess: true
        })

        setOpen(false)
    }

    const handleSuccessPayment = async () => {
        await Auth.signOut()
    }

    const handleErrorPayment = () => {
    }

    const handleCancelPayment = async () => {
        if (subscriptionId) {
            setSubscriptionId(undefined)

            try {
                await deletePendingSubscription(subscriptionId)
            } catch (error) {
                if (error.status !== 404) {
                    throw error
                } else {
                    return
                }
            }

            await Auth.signOut()
        }
    }

    const handleClosePayment = async () => {
        await handleCancelPayment()
    }

    const addReepayEventHandlers = () => {
        if (!rpModalRef.current) {
            return
        }

        rpModalRef.current.addEventHandler(Reepay.Event.Accept, handleSuccessPayment)
        rpModalRef.current.addEventHandler(Reepay.Event.Error, handleErrorPayment)
        rpModalRef.current.addEventHandler(Reepay.Event.Cancel, handleCancelPayment)
        rpModalRef.current.addEventHandler(Reepay.Event.Close, handleClosePayment)
    }

    const onClose = async () => {
        await Auth.signOut()
        setOpen(false)
    }
    return (
        <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={open}
            onClose={onClose}>
            <div style={modalStyle} className={classes.paperBigger}>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
                    <GoBackHeader headerText="Vælg en plan" onClick={onClose} disabled={busy} />

                    {/* <PricesComponentParent
                        showSmall
                        selected={selectedPrice}
                        onChange={setSelectedPrice} /> */}
                    <img
                        style={{
                            width: 300,
                            height: 300,
                            marginLeft: 'auto',
                            marginRight: 'auto'
                        }}
                        src={PricePlanImage}
                        alt="price plan" />

                    <TextInputTemplate
                        value={coupon}
                        disabled={busy}
                        onChange={setCoupon}
                        label="Kupon" />
                    <Button
                        style={{ marginTop: '1vh', width: '50%', left: '25%' }}
                        variant="contained"
                        color="primary"
                        disabled={busy || !teacher}
                        onClick={submitPayment}>
                        Betal
                    </Button>
                </div>
            </div>
        </Modal>
    )
}
export default PaymentModal
