import * as React from 'react'
import { useLocation } from 'react-router-dom'

import { Loading } from '../../components/molecules/Loading'
import PaginatedList from '../../components/molecules/PaginatedList'
import LibraryScreen from '../../components/organisms/LibraryScreen'
import { BodyWithTopBar } from '../../components/wrappers/BodyWithTopBar'
import { PDFType } from '../../types/types'
import { possiblePDFCategories, possibleQuizTopics } from '../../utils/globalData'
import { sortItemsByCreatedAtString } from '../../utils/helper'
import { listPDFs } from '../../utils/pdf-service'

export const PDFLibraryPage: React.FC = () => {
    const location: any = useLocation()
    // eslint-disable-next-line no-nested-ternary
    const defaultSearchValue = location.state
        ? location.state.className ? location.state.search : ''
        : ''

    const [rows, setRows] = React.useState<PDFType[]>([])
    const [searchValue, setSearchValue] = React.useState<string>(defaultSearchValue)
    const [loading, setLoading] = React.useState<boolean>(true)

    const requestSearch = (searchedVal: string) => {
        setSearchValue(searchedVal)
    }

    const updateRows = (newRows: any[]) => {
        setRows(sortItemsByCreatedAtString(newRows))
    }

    React.useEffect(() => {
        (async () => {
            setLoading(true)
            const fetchedPDFs = await listPDFs()
            setLoading(false)
            updateRows(fetchedPDFs)
        })()
    }, [])

    return (
        <BodyWithTopBar>
            {loading ? <Loading />
                : (
                    <LibraryScreen
                        items={rows}
                        listOfCategories={possiblePDFCategories}
                        listOfTopics={possibleQuizTopics}
                        setRows={(item) => updateRows(item)}
                        headerText="Geeksters PDFs"
                        searchFunction={requestSearch}
                        searchValue={searchValue}
                        typeOfLibrary="pdf">
                        <PaginatedList data={rows} typeOfLibrary="pdf" />
                    </LibraryScreen>
                )}
        </BodyWithTopBar>
    )
}
