import { Button, createStyles, makeStyles } from '@material-ui/core'
import * as React from 'react'

interface Props {
    itemTag: string,
}

const useStyles = makeStyles(() => createStyles({
    itemTags: {
        marginBottom: '1.2vh',
        marginRight: 5,
        marginTop: '1.2vh',
        '&:disabled': {
            background: '#70d1f9',
        },
        textTransform: 'none'
    },
}),)

const LibraryItemTag: React.FC<Props> = ({ itemTag }) => {
    const classes = useStyles()
    return (
        <Button className={classes.itemTags} variant="contained" color="primary" disabled>{itemTag}</Button>
    )
}
export default LibraryItemTag
