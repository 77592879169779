import { createTheme, ThemeProvider } from '@material-ui/core'
import * as React from 'react'

import { primary, primaryContrast } from '../../utils/colors'

const theme = createTheme({
    palette: {
        primary: { main: primary, contrastText: primaryContrast },
        secondary: { main: '#A0A0C7' },
        background: {
            default: '#fff'
        }
    },
    typography: {
        fontFamily: 'sans-serif',
        button: {
            textTransform: 'none'
        }
    }
})

export const Body: React.FC = ({ children }) => (
    <>
        <ThemeProvider theme={theme}>
            {children}
        </ThemeProvider>

    </>
)
