import { API } from 'aws-amplify'

import {
    CommunityQuizType, FileType, LibraryFilter, Question
} from '../../types/types'

export const getCommunityQuizById = async (quizId: string): Promise<CommunityQuizType> => {
    const quiz = await API.get('api', `/quizzes/community/${quizId}`, {})
    return quiz
}
export const listCommunityQuizzes = async (): Promise<CommunityQuizType[]> => {
    const quizzes = await API.get('api', '/quizzes/community', {})
    return quizzes
}
export const listPublicCommunityQuizzes = async (): Promise<CommunityQuizType[]> => {
    const publicQuizzes = await API.get('api', '/quizzes/community/public', {})
    return publicQuizzes
}

export const createInitiallyCommunityQuiz = async (title: string, topics: string[],
    categories: string[], grades: number[], isPublic: boolean,
    onlyOrderedResponse: boolean): Promise<CommunityQuizType> => {
    return API.post('api', '/quizzes/community', {
        body: {
            title, topics, categories, grades, isPublic, onlyOrderedResponse
        }
    })
}

export const finishCreatingCommunityQuiz = async (quizId: string, description: string,
    questions: Question[], file: FileType | undefined): Promise<CommunityQuizType> => {
    return API.put('api', `/quizzes/community/${quizId}`, {
        body: {
            description, questions, file
        }
    })
}

export const updateCommunityQuizQuestions = async (quizId: string, questions: Question[]):
    Promise<CommunityQuizType> => {
    return API.put('api', `/quizzes/community/${quizId}`, {
        body: {
            questions
        }
    })
}

export const addQuestionToCommunityQuiz = async (
    quizId: string, title: string, explanation: string,
    responses: any[], vimeoVideoId?: string, youtubeVideoId?: string, picture?: FileType | undefined
): Promise<CommunityQuizType> => {
    return API.put('api', `/quizzes/community/question/${quizId}`, {
        body: {
            title, explanation, responses, vimeoVideoId, youtubeVideoId, picture
        }
    })
}
export const updateCommunityQuiz = async (quizId: string, title: string, description: string,
    file: FileType | undefined, categories: string[], topics: string[],
    grades: number[], questions: Question[], isPublic: boolean,
    onlyOrderedResponse: boolean): Promise<CommunityQuizType> => {
    return API.put('api', `/quizzes/community/${quizId}`, {
        body: {
            title, description, categories, file, topics, grades, questions, isPublic, onlyOrderedResponse
        }
    })
}

export const deleteCommunityQuiz = async (quizId: string) => {
    return API.del('api', `/quizzes/community/${quizId}`, {})
}
export const filterCommunityQuizzes = async (filterParams: LibraryFilter): Promise<CommunityQuizType[]> => {
    const quizzes = await API.post('api', '/quizzes/community/filter', {
        body: filterParams
    })
    return quizzes
}
