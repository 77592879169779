import { Slide, Snackbar } from '@material-ui/core'
import { Alert, Color } from '@material-ui/lab'
import * as React from 'react'

export type Severity = Color
type Props = {
    show: boolean
    onClose(): void
    severity: Severity
    message: string
}

export const NotificationPopup: React.FC<Props> = ({
    show, severity, message, onClose
}) => {
    return (
        <Snackbar
            open={show}
            autoHideDuration={5000}
            onClose={onClose}
            TransitionComponent={Slide}>
            <Alert severity={severity}>
                {message}
            </Alert>
        </Snackbar>
    )
}
