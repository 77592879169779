import React from 'react'
import {
    Redirect, Route, useLocation 
} from 'react-router-dom'

type Props = {
    children: any,
    exact?: boolean,
    path: string,
    signedIn?: boolean,
    type: 'auth' | 'not-auth' | 'any'
}

const AppRoute: React.FC<Props> = ({
    children, exact, path, type, signedIn 
}) => {
    const { pathname, search, state } = useLocation()

    let inner = children
    if (type === 'not-auth') {
        if (signedIn === true) {
            inner = <Redirect to={state ? state.redirect : '/'} />
        } else if (signedIn === undefined) {
            inner = <></>
        }
    } else if (type === 'auth') {
        if (signedIn === false) {
            inner = <Redirect to={{ pathname: '/login', state: { redirect: `${pathname}${search}` } }} />
        } else if (signedIn === undefined) {
            inner = <></>
        }
    }

    return (
        <Route {...exact} path={path}>
            { inner }
        </Route>
    )
}

export default AppRoute
