import {
    Paper, TableContainer, Typography
} from '@material-ui/core'
import React from 'react'

import PricePlanImage from '../../assets/24kr.png'
import { PricePlan } from '../../types/types'
import { useTableStyles } from '../../utils/styles'

interface Props {
    showSubscriptionOptions: boolean,
    // selectedPricePlan: PricePlan | undefined,
    setSelectedPricePlan: (selectedPricePlan: PricePlan) => void,
}

export const SignUpForm: React.FC<Props> = ({
    showSubscriptionOptions,
    // selectedPricePlan,
    setSelectedPricePlan,
    children,
}) => {
    const tableClasses = useTableStyles()

    const headline = () => {
        return (
            <div
                style={{
                    paddingTop: 20,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                <Typography variant="h5">
                    Denne side er for dig som lærer, der er tilknyttet en skole med adgang
                </Typography>
                <Typography variant="h6">
                    Opret dig nedenfor, og brug din skoles kupon til oprettelsen
                </Typography>
            </div>
        )
    }
    React.useEffect(() => {
        setSelectedPricePlan('1monthParent')
    }, [])

    return (
        <form noValidate>
            <TableContainer
                style={{
                    backgroundColor: 'rgba(252,252,252,0.9)'
                }}
                className={tableClasses.table}
                component={Paper}>
                <div>
                    {showSubscriptionOptions ? (
                        <div style={{ display: 'flex' }}>
                            <img
                                style={{
                                    width: 300,
                                    height: 300,
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                                src={PricePlanImage}
                                alt="price plan" />
                            {/* <PricesComponentParent
                                selected={selectedPricePlan}
                                onChange={setSelectedPricePlan} /> */}

                        </div>
                    )
                        : headline()}
                    <div className={tableClasses.divInputs}>
                        {children}
                    </div>
                </div>
            </TableContainer>
        </form>
    )
}
