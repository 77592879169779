import {
    makeStyles, Theme
} from '@material-ui/core'
import { colorify } from 'lottie-colorify'
import React from 'react'
import { isMobile } from 'react-device-detect'
import Lottie from 'react-lottie'

import animationData from '../../assets/confetti.json'

const useStyles = makeStyles((theme: Theme) => ({
    confetti: {
        position: 'absolute',
        top: -165,
        bottom: 0,
        left: -120,
        right: 0,
        transform: isMobile ? 'translate(-25%, -180px)' : 'translateY(-180px)',
        textAlign: 'center',
        zIndex: 9999,
        [theme.breakpoints.down('md')]: {
            transform: isMobile ? 'translate(-25%, -180px)' : 'translateY(-140px)',
        },
        [theme.breakpoints.down('sm')]: {
            left: isMobile ? '30%' : null,
        },
        [theme.breakpoints.down('xs')]: {
            transform: isMobile ? 'translate(-50vw, -180px)' : null,
            right: isMobile ? '0vw' : null,
        }
    }
}))

export const ConfettiContainer: React.FC = () => {
    const classes = useStyles()
    const [first, setFirst] = React.useState('')
    const [second, setSecond] = React.useState('')
    const [third, setThird] = React.useState('')
    const [fourth, setFourth] = React.useState('')

    function getRandomColor() {
        const letters = '0123456789ABCDEF'
        let color = '#'
        for (let i = 0; i < 6; i += 1) {

            color += letters[Math.floor(Math.random() * 16)]
        }
        return color
    }
    React.useEffect(() => {
        setFirst(getRandomColor())
        setSecond(getRandomColor())
        setThird(getRandomColor())
        setFourth(getRandomColor())
    }, [])

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: colorify([
            ...Array(5).fill(first),
            ...Array(6).fill(second),
            ...Array(5).fill(third),
            ...Array(5).fill(fourth),
            ...Array(5).fill(first),
            ...Array(5).fill(second),
            ...Array(6).fill(third),
            ...Array(5).fill(fourth),

        ], animationData),
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    }
    return (
        <div className={classes.confetti}>
            <Lottie
                options={defaultOptions}
                height={500}
                width={500} />
        </div>
    )
}
