import { Auth } from 'aws-amplify'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useAsync } from 'react-async-hook'

import { SubscriptionInfo, TeacherType } from '../types/types'
import { showMessage } from './notifications'
import { listClasses } from './Services/classes-service'
import { listCommunityQuizzes } from './Services/community-quiz-service'
import { getHomeworkClassNames } from './Services/homework-service'
import { getActiveCustomerSubscriptions } from './Services/reepay-service'
import { listStudentClasses } from './Services/student-service'
import {
    getById, getHomeworksByTeacherId, getIsParent, getStudentsByTeacherId
} from './Services/teacher-service'

export const TeacherContext: React.Context<any> = React.createContext({
    fetchHomeworks: () => { },
    fetchStudents: () => { },
    fetchClasses: () => { },
    fetchClassNames: () => { },
    fetchSubscriptionData: () => { },
    fetchStudentClassNames: () => { },
    fetchCommunityQuizzes: () => { },
    teacher: undefined,
    setTeacher: () => { }
})

// define a useContext hook for functional components
export const useTeacher = () => React.useContext(TeacherContext)

export const TeacherManagerWrapped = (props: any) => {
    // const location = useLocation()

    // React.useEffect(() => {
    //     console.log('pathname')
    // }, [location])
    const [confetti, setConfetti] = useState<boolean | null>(null)
    const [teacher, setTeacher] = useState<TeacherType>()

    const getSortedStudents = async () => {
        const fetchedStudents = await getStudentsByTeacherId()
        return fetchedStudents.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))
    }

    const getSortedHomeworks = async () => {
        const fetchedHomeworks = await getHomeworksByTeacherId()
        return fetchedHomeworks.sort((o1, o2) => {
            if (o1.createdAt > o2.createdAt) return -1
            else if (o1.createdAt < o2.createdAt) return 1
            else return 0
        })
    }

    const getClassNames = async (): Promise<string[][]> => {
        const homeworks = await getSortedHomeworks()

        if (homeworks) {
            const fetchedClassNames = homeworks.map(async (item) => {
                const homeworksClasses = await getHomeworkClassNames(item.id)
                return homeworksClasses
            })
            const names = await Promise.all(fetchedClassNames)
            return names
        }
        return []
    }

    const getTeacherSubscriptionData = async (): Promise<SubscriptionInfo | undefined> => {
        const userId = await Auth.Credentials.Auth.user.attributes.sub
        const subs = await getActiveCustomerSubscriptions(userId)
        const sub = subs[0]
        if (sub) {
            const joinedAt = moment.utc(sub.joinedAt)
            const expiresAt = moment.utc(sub.expiresAt)

            const daysLeft = expiresAt.diff(moment.utc(), 'days')
            return {
                dateOfRegistration: `d. ${joinedAt.format('ll')}`,
                remainingDays: `${daysLeft} dage`
            }
        }
        showMessage('Intet aktivt medlemskab fundet')
        return undefined
    }

    const getStudentClassNames = async () => {
        const students = await getSortedStudents()

        const namePromises = students.map(async (student) => {
            const classes = await listStudentClasses(student.id)
            return {
                id: student.id,
                classes: classes.map((c) => c.title).join(', ')
            }
        })

        const names = await Promise.all(namePromises)
        return Object.assign({}, ...names.map((x) => {
            return ({ [x.id]: x.classes })
        })) as Record<string, string>
    }

    const fetchHomeworks = useAsync(getSortedHomeworks, [])
    const fetchStudents = useAsync(getSortedStudents, [])
    const fetchClasses = useAsync(listClasses, [])
    const fetchClassNames = useAsync(getClassNames, [])
    const fetchSubscriptionData = useAsync(getTeacherSubscriptionData, [])
    const fetchStudentClassNames = useAsync(getStudentClassNames, [])
    const fetchCommunityQuizzes = useAsync(listCommunityQuizzes, [])
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [isParent, setIsParent] = useState<boolean | null | string>(null)
    const fetchIsParent = useAsync(getIsParent, [])

    useEffect(() => {
        (async () => {
            // @ts-ignore function
            setIsParent(await getIsParent() === 'true')
            setIsLoading(false)
        })()
    }, [])

    useEffect(() => {
        (async () => {
            const t = await getById()
            setTeacher(t)
            setConfetti(t.confetti)
        })()
    }, [])
    // plug rhe values and functions into your
    // context that will be accessible to all
    // props.children
    return (
        <TeacherContext.Provider value={{
            confetti,
            setConfetti,
            fetchIsParent,
            isLoading,
            isParent,
            fetchHomeworks,
            fetchStudents,
            fetchClasses,
            fetchClassNames,
            fetchSubscriptionData,
            fetchStudentClassNames,
            fetchCommunityQuizzes,
            teacher,
            setTeacher
        }}>
            {props.children}
        </TeacherContext.Provider>
    )
}
