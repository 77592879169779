import { Auth, Hub } from 'aws-amplify'
import React from 'react'

import { Severity } from '../../components/molecules/NotificationPopup'
import Config from '../../config'
import { DelegatedCognitoLogin } from '../../types/types'

type CognitoOpts = {
    onChange: (signedIn: boolean) => void
}

const prefix = 'CognitoIdentityServiceProvider'

export const handleSignInError = (errorMessage: string, renderError: (message: string, severity: Severity) => void) => {
    switch (errorMessage) {
        case 'Incorrect username or password.':
            renderError('Du har indtastet et forkert brugernavn eller kodeord.', 'error')
            break
        default:
            renderError(`${errorMessage}`, 'error')
    }
}

export const restoreCognitoSession = (creds: DelegatedCognitoLogin) => {
    localStorage.setItem(`${prefix}.${Config.Auth.userPoolWebClientId}.${creds.Username}.idToken`,
        creds.IdToken)
    localStorage.setItem(`${prefix}.${Config.Auth.userPoolWebClientId}.${creds.Username}.refreshToken`,
        creds.RefreshToken)
    localStorage.setItem(`${prefix}.${Config.Auth.userPoolWebClientId}.${creds.Username}.accessToken`,
        creds.AccessToken)
    localStorage.setItem(`${prefix}.${Config.Auth.userPoolWebClientId}.LastAuthUser`,
        creds.Username)

    window.location.pathname = '/'
    window.location.search = ''
}

export const useCognito = (opts?: CognitoOpts) => {
    const [signedIn, _setSignedIn] = React.useState<boolean>()

    const setSignedIn = (v: boolean) => {
        if (opts?.onChange) {
            opts.onChange(v)
        }
        _setSignedIn(v)
    }

    const authListener = React.useCallback(({ payload: { event } }) => {
        // eslint-disable-next-line no-console
        console.log('new auth event: ', event)
        // eslint-disable-next-line default-case
        switch (event) {
            case 'signIn':
                setSignedIn(true)
                break
            case 'signOut':
                setSignedIn(false)
                break
        }
    }, [])

    const checkLoginStatus = async () => {
        try {
            await Auth.currentAuthenticatedUser()
            setSignedIn(true)
        } catch (error) {
            setSignedIn(false)
        }
    }

    React.useEffect(() => {
        Hub.listen('auth', authListener)

        checkLoginStatus().then()

        return () => { Hub.remove('auth', authListener) }
    }, [])

    return { signedIn, setSignedIn }
}
