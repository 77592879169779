import {
    Paper, TableContainer, Typography
} from '@material-ui/core'
import * as React from 'react'

import { BottomBar } from '../../components/molecules/BottomBar'
import { ContactForm } from '../../components/molecules/ContactForm'
import { Loading } from '../../components/molecules/Loading'
import { useTableStyles } from '../../utils/styles'

export const Contact: React.FC = () => {
    const tableClasses = useTableStyles()
    const [loading] = React.useState<boolean>(false)

    return (
        <div className={tableClasses.authBackground2}>
            <div style={{
                display: 'flex',
                paddingTop: 100
            }}>
                {loading ? <Loading />
                    : (

                        <TableContainer className={tableClasses.table1} component={Paper}>
                            <div style={{ padding: 100 }}>
                                <Typography variant="h4" style={{ textAlign: 'center', marginTop: 20 }}>
                                    Kontakt os
                                </Typography>

                                <ContactForm/>
                            </div>
                        </TableContainer>
                    )}
                <BottomBar onlyPicture />
            </div>
        </div>
    )
}
