import { API } from 'aws-amplify'

import { ClassType, FetchedStudentNames, HomeworkType } from '../../types/types'

export const listClasses = async (): Promise<ClassType[]> => {
    const classes = await API.get('api', '/classes', {})
    return classes
}

export const getClass = async (id: string): Promise<ClassType> => {
    const classItem = await API.get('api', `/class/${id}`, {})
    return classItem
}

export const getClassStudentNames = async (id: string): Promise<FetchedStudentNames[]> => {
    const classItem = await API.get('api', `/class/student/names/${id}`, {})
    return classItem
}

export const createClass = async (title: string, description: string) => {
    return API.post('api', '/class', {
        body: { title, description }
    })
}

export const updateClass = async (title: string, description: string, id: string) => {
    return API.put('api', `/class/${id}`, {
        body: { title, description }
    })
}

export const deleteClass = async (id: string) => {
    return API.del('api', `/class/${id}`, {})
}

export const getClassHomeworks = async (id: string): Promise<HomeworkType[]> => {
    const homeworks = await API.get('api', `/class/homework/${id}`, {})
    return homeworks
}
