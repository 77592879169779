import { Button, TextField } from '@material-ui/core'
import React, { useState } from 'react'

import { submitContactForm } from '../../utils/Services/contact-form-service'
import { wrap } from '../../utils/wrap-functions'
import { Loading } from './Loading'

export const ContactForm: React.FC = () => {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')
    const [busy, setBusy] = useState(false)

    const submit = async () => {
        await wrap(async () => {
            await submitContactForm(email, name, message)
        }, {
            operation: 'Submitting',
            showSuccess: true,
            stateFunc: setBusy
        })
        setName('')
        setEmail('')
        setMessage('')
    }

    if (busy) {
        return <Loading/>
    }

    return (
        <div style={{ width: '68%', margin: 'auto' }}>
            <TextField
                style={{ margin: 8 }}
                value={name}
                variant="outlined"
                fullWidth
                label="Navn"
                type="text"
                onChange={(c) => setName(c.target.value)}/>
            <TextField
                style={{ margin: 8 }}
                value={email}
                variant="outlined"
                fullWidth
                label="Email"
                type="email"
                onChange={(c) => setEmail(c.target.value)}/>
            <TextField
                style={{ margin: 8 }}
                fullWidth
                variant="outlined"
                label="Besked"
                multiline
                minRows={2}
                maxRows={4}
                value={message}
                onChange={(c) => setMessage(c.target.value)}/>
            <Button
                variant="contained"
                style={{ backgroundColor: 'green', color: 'white', marginLeft: 8 }}
                type="button"
                onClick={submit}>Send
            </Button>
        </div>
    )
}
