import { Typography } from '@material-ui/core'
import * as React from 'react'

interface Props {
    headerText?: string,
    smallerText?: string
}

const LibraryTextProperties: React.FC<Props> = ({ headerText, smallerText }) => {
    return (
        <>
            <Typography variant="body1"><b>{headerText}</b></Typography>
            <Typography variant="body1">{smallerText}</Typography>
        </>
    )
}
export default LibraryTextProperties
