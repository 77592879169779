import { API } from 'aws-amplify'

import { CouponValidityType, CustomerActiveSubscriptions } from '../../types/types'

export const prepareSubscription = async (
    customerId: string,
    email: string,
    lastName: string,
    pricePlan: string,
    couponCodes: string[] | undefined
) => {
    return API.post('openapi', '/subscription/prepare', {
        body: {
            customerId, email, lastName, pricePlan, couponCodes
        }
    })
}

export const createSubscription = async (
    customerId: string,
    email: string,
    lastName: string,
    pricePlan: string,
    couponCodes: string[] | undefined
) => {
    return API.post('openapi', '/subscription/create', {
        body: {
            customerId, email, lastName, pricePlan, couponCodes
        }
    })
}

export const getSubscriptionSession = async (handle: string) => {
    return API.post('openapi', '/subscriptionsession', {
        body: {
            handle,
            acceptUrl: window.location.host.includes('localhost') ? `http://${window.location.host}/login`
                : `https://${window.location.host}/login`,
        }
    })
}

export const getActiveCustomerSubscriptions = async (handle: string) => {
    return (
        await API.post('api', '/customer/subscriptions', {
            body: {
                handle
            }
        }) as CustomerActiveSubscriptions
    ).subscriptions
}

export const deletePendingSubscription = async (handle: string) => {
    return API.del('api', '/subscription/pending/delete', {
        body: {
            handle
        }
    })
}

export const validateCoupon = async (coupon: string) => {
    return await API.get('openapi', `/subscription/coupon/${coupon}`, {}) as CouponValidityType
}

export const cancelSubscription = async (handle: string) => {
    return API.post('api', '/subscription/cancel', {
        body: {
            handle
        }
    })
}

export const getSubscription = async (handle: string) => {
    return API.post('api', '/subscription', {
        body: {
            handle
        }
    })
}
